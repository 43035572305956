import 'cross-fetch/polyfill'
import { ApolloProvider } from '@apollo/client'
import { publicRuntimeConfig } from '../../config/config'

import { ApolloClientSingleton } from '../../apollo/client'
import { CognitoApolloClientSingleton } from '../../apollo/cognitoClient'

const ApolloWrapper: React.FC = ({ children }) => {
  const client = publicRuntimeConfig.NEXT_PUBLIC_COGNITO_AUTH_ENABLED
    ? CognitoApolloClientSingleton.getInstance()
    : ApolloClientSingleton.getInstance()
  return <ApolloProvider client={client}>{children}</ApolloProvider>
}

export default ApolloWrapper
