import { useEffect, memo } from 'react'

import { AppProps } from 'next/app'
import Head from 'next/head'
import { useRouter } from 'next/router'

import { StylesProvider, CssBaseline, Box } from '@material-ui/core'

import ThemeProvider from '@components/ThemeProvider/ThemeProvider'
import GlobalStyle from '@components/GlobalStyle/GlobalStyle'
import Header from '@components/Header/Header'
import RaiseFooter from '@components/Nav/RaiseFooter/RaiseFooter'
import SegmentIdentify from '@components/SegmentIdentify/SegmentIdentify'
import SoundProvider from '@components/SoundProvider/SoundProvider'
import ApolloWrapper from '@modules/ApolloWrapper/ApolloWrapper'
import AuthProviderWrapper from '@modules/AuthProvider/AuthProviderWrapper'

import { publicRuntimeConfig } from 'config/config'
import FreshChatScript from '@components/Script/FreshChat/FreshChat'
import TiktokPixelTrackerScript from '@components/Script/TiktokPixelTracker/TiktokPixelTracker'
import GoogleTagManagerScript from '@components/Script/GoogleTagManager/GoogleTagManager'
import OrganizationJsonLD from '@components/Script/OrganizationJsonLD/OrganizationJsonLD'
import FeatureFlagProvider from '@components/FeatureFlag/FeatureFlagProvider'
import BottomMenuNavigation from '@components/BottomMenuNavigation/BottomMenuNavigation'
import '../styles/customFonts.css'
import 'swiper/swiper.min.css'

const App: React.FC<AppProps> = ({ Component, pageProps }) => {
  const router = useRouter()

  const pagesWithFreshchatDisabled = [
    // The homepage
    '/',
    // The public profile page
    '/u/[username]',
    // Public application pages
    '/u/[username]/a/[applicationId]',
  ]

  const pagesWithBottomNavBarDisabled = [
    // Chat
    '/conversations/[conversationId]',
  ]

  const pagesWithTopNavBarDisabled = [
    // Chat
    '/conversations/[conversationId]',
    // collab pages
    '/[username]/collab',
    '/[username]/feed',
    '/feed',
  ]
  const isTopNavBarEnabled = !pagesWithTopNavBarDisabled.includes(router.pathname)
  const isBottomNavBarEnabled = !pagesWithBottomNavBarDisabled.includes(router.pathname)

  // const isFreshchatEnabled = !pagesWithFreshchatDisabled.includes(router.pathname)

  useEffect(() => {
    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector('#jss-server-side')
    if (jssStyles) {
      jssStyles.parentElement?.removeChild(jssStyles)
    }
  }, [])

  return (
    <>
      <Head>
        <title>Protégé | Access to the best experts in the world</title>
        <meta name="googlebot" content="notranslate" />
        <meta name="google" content="notranslate" />

        <meta
          name="description"
          content="Submit your talent and become the protégé of leading experts in Music, Venture Capital, Content, Tech and Entertainment"
          key="description"
        />
        <meta
          name="keywords"
          content="Protégé, Protege, Protege App, audition, opportunity"
          key="keywords"
        />
        <meta
          name="viewport"
          content="maximum-scale=1, initial-scale=1, width=device-width, user-scalable=0"
          key="viewport"
        />
        <meta
          property="og:image"
          content={`${publicRuntimeConfig.NEXT_PUBLIC_SITE_URL}/images/frame.png`}
          key="og:image"
        />
        <meta property="og:title" content="Protégé: Be Heard. Get Discovered" key="og:title" />
        {/* Twitter Card data */}
        <meta name="twitter:card" content="summary" key="twitter:card" />
        <meta name="twitter:site" content="@protege" key="twitter:site" />
        <meta
          name="twitter:title"
          content="Protégé: Be Heard. Get Discovered"
          key="twitter:title"
        />
        <meta
          name="twitter:image"
          content={`${publicRuntimeConfig.NEXT_PUBLIC_SITE_URL}/images/frame.png`}
          key="twitter:image"
        />
        {/* Twitter summary card with large image */}
        <meta name="twitter:creator" content="@protege" key="twitter:creator" />
        <meta
          name="facebook-domain-verification"
          content="8dg5nsakbko8apjy57xijhplpq0s3i"
          key="facebook-domain-verification"
        />
        {publicRuntimeConfig.NEXT_PUBLIC_PROTEGE_ENV !== 'integration' && (
          <TiktokPixelTrackerScript />
        )}
      </Head>
      {/* FreshChat */}
      {/* {router.isReady && isFreshchatEnabled && <FreshChatScript />} */}
      <GoogleTagManagerScript />
      <OrganizationJsonLD />
      <ApolloWrapper>
        <SegmentIdentify />
        <StylesProvider injectFirst>
          <ThemeProvider>
            <AuthProviderWrapper>
              <FeatureFlagProvider>
                <GlobalStyle />
                {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
                <CssBaseline />
                <SoundProvider>
                  <Box display="flex" minHeight="100vh" flexDirection="column" flexWrap="column">
                    {isTopNavBarEnabled && <Header />}
                    <Box flex={1}>
                      <Component {...pageProps} />
                    </Box>
                    <RaiseFooter />
                  </Box>
                </SoundProvider>
              </FeatureFlagProvider>
            </AuthProviderWrapper>
          </ThemeProvider>
        </StylesProvider>
      </ApolloWrapper>
    </>
  )
}

export default memo(App)
