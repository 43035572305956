import theme from '@components/theme'
import styled, { keyframes } from 'styled-components'

export enum SpinnerSize {
  SMALL = 'SMALL',
  MEDIUM = 'MEDIUM',
  LARGE = 'LARGE',
}

const spinnerSizeMap: { [key in keyof typeof SpinnerSize]: number } = {
  [SpinnerSize.SMALL]: 30,
  [SpinnerSize.MEDIUM]: 60,
  [SpinnerSize.LARGE]: 90,
}

export const Screen = styled.div<{ full?: boolean }>`
  position: relative;
  height: ${({ full }) => (full ? `calc(100vh - (${theme.navigation.height}px * 2))` : 'auto')};
  width: 100%;
`

export const SpinnerWrapper = styled.div<{ size: keyof typeof SpinnerSize }>`
  height: ${({ size }) => spinnerSizeMap[size]}px;
  width: ${({ size }) => spinnerSizeMap[size]}px;
  overflow: hidden;
  border-radius: 50%;
`

export const LoadingCircle = styled.div<{ size: keyof typeof SpinnerSize }>`
  position: absolute;
  left: 50%;
  top: 50%;
  width: ${({ size }) => spinnerSizeMap[size]}px;
  height: ${({ size }) => spinnerSizeMap[size]}px;
  margin-left: -${({ size }) => spinnerSizeMap[size] / 2}px;
  margin-top: -${({ size }) => spinnerSizeMap[size] / 2}px;
  border-radius: 50%;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.1);
`

const rotateAnimation = keyframes`
  0% { transform: rotate(0deg);} 
  100% { transform: rotate(345deg);}
`
export const LoadingLineMask = styled.div<{ size: keyof typeof SpinnerSize }>`
  position: absolute;
  left: 50%;
  top: 50%;
  width: ${({ size }) => spinnerSizeMap[size] / 2}px;
  height: ${({ size }) => spinnerSizeMap[size]}px;
  margin-left: -${({ size }) => spinnerSizeMap[size] / 2}px;
  margin-top: -${({ size }) => spinnerSizeMap[size] / 2}px;
  overflow: hidden;
  transform-origin: ${({ size }) => spinnerSizeMap[size] / 2}px
    ${({ size }) => spinnerSizeMap[size] / 2}px;
  mask-image: linear-gradient(top, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
  animation-name: ${rotateAnimation};
  animation-duration: 1.2s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
`

export const LoaderLine = styled.div<{ size: keyof typeof SpinnerSize }>`
  width: ${({ size }) => spinnerSizeMap[size]}px;
  height: ${({ size }) => spinnerSizeMap[size]}px;
  border-radius: 50%;
  box-shadow: inset 0 0 0 1px rgba(180, 180, 180, 0.5);
`
