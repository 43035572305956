import styled from 'styled-components'

export const Paper = styled.div`
  margin-top: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const CurrentStep = styled.div`
  background-color: ${({ theme }) => theme.color.darkerBlue};
  border: 6px solid #c7d2fe;
  border: ${({ theme }) => `${theme.border.width.large} solid ${theme.color.paleCerulean}`};
  height: 20px;
  border-radius: ${({ theme }) => theme.border.radius.circle};
  width: 20px;
  margin-left: 15px;
`

export const Step = styled.div`
  background-color: ${({ theme }) => theme.border.color.gray};
  height: 9px;
  border-radius: 50%;
  width: 9px;
  margin-left: 15px;
`
