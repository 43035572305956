import styled, { css } from 'styled-components'

import theme from '../theme'
import { styles, fontWeight, Variant, FontWeight } from './Typography.config'
import { Color as ColorType, ResponsiveVariantType, VariantWeight } from './Typography'
import { Breakpoint } from '@material-ui/core/styles/createBreakpoints'

const isVariantWeight = (variant: string | VariantWeight) => {
  if (typeof variant === 'object') {
    return true
  }
  return false
}

const responsiveBreakpointCss = (breakpoint: Breakpoint) => css<{
  variant: ResponsiveVariantType
  weight?: FontWeight
}>`
  ${(props) =>
    props.variant[breakpoint] &&
    css`
      ${({ theme }) => theme.breakpoints.up(breakpoint)} {
        ${styles[props.variant[breakpoint] as string]};
      }
    `}

  ${(props) =>
    props.variant[breakpoint] &&
    !!props.weight &&
    css`
      ${({ theme }) => theme.breakpoints.up(breakpoint)} {
        font-weight: ${fontWeight[props.weight]};
      }
    `}
`

const specificResponseVariantWeightCss = (breakpoint: Breakpoint) => css<{
  variant: ResponsiveVariantType
  weight?: FontWeight
}>`
  ${(props) =>
    props.variant[breakpoint] &&
    css`
      ${({ theme }) => theme.breakpoints.up(breakpoint)} {
        ${styles[(props.variant[breakpoint] as VariantWeight).variant]};
      }
    `}

  ${(props) =>
    props.variant[breakpoint] &&
    !(props.variant[breakpoint] as VariantWeight).weight &&
    !!props.weight &&
    css`
      ${({ theme }) => theme.breakpoints.up(breakpoint)} {
        font-weight: ${fontWeight[props.weight]};
      }
    `}

    ${(props) =>
    props.variant[breakpoint] &&
    (props.variant[breakpoint] as VariantWeight).weight &&
    css`
      ${({ theme }) => theme.breakpoints.up(breakpoint)} {
        font-weight: ${fontWeight[(props.variant[breakpoint] as VariantWeight).weight!]};
      }
    `}
`

const breakpointCssMixin = (breakpoint: Breakpoint) => css<{
  variant: ResponsiveVariantType
  weight?: FontWeight
}>`
  ${(props) =>
    props.variant[breakpoint] &&
    !isVariantWeight(props.variant[breakpoint]!) &&
    responsiveBreakpointCss(breakpoint)}

  ${(props) =>
    props.variant[breakpoint] &&
    isVariantWeight(props.variant[breakpoint]!) &&
    specificResponseVariantWeightCss(breakpoint)}
`

const colorCssMixin = css<{ color?: ColorType | string }>`
  ${(props) =>
    props.color &&
    css`
      color: ${theme.font.color[props.color] ? theme.font.color[props.color] : props.color};
    `}
`
const fontWeightCssMixin = css<{ weight?: string }>`
  ${(props) =>
    !!props.weight &&
    css`
      font-weight: ${fontWeight[props.weight] ? fontWeight[props.weight] : props.weight};
    `}
`
const upperCaseCssMixin = css<{ uppercase?: boolean }>`
  ${(props) =>
    props.uppercase &&
    css`
      text-transform: uppercase;
    `}
`

export const Color = styled.span<{
  color?: ColorType | string
  uppercase?: boolean
  weight?: FontWeight
  variant: keyof typeof Variant
}>`
  ${colorCssMixin}
  ${upperCaseCssMixin}

  ${(props) =>
    props.variant &&
    css`
      ${styles[props.variant as string]}
    `}

  ${fontWeightCssMixin}
`

export const ResponsiveVariant = styled.span<{
  variant: ResponsiveVariantType
  color?: ColorType | string | null
  uppercase?: boolean
  weight?: FontWeight
}>`
  ${colorCssMixin}
  ${upperCaseCssMixin}
  ${fontWeightCssMixin}
  ${breakpointCssMixin('xs')}
  ${breakpointCssMixin('sm')}
  ${breakpointCssMixin('md')}
  ${breakpointCssMixin('lg')}
  ${breakpointCssMixin('xl')}
`
