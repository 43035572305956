import { ThemeProvider as SCThemeProvider } from 'styled-components'
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles'

import theme from '../theme'

// https://material-ui.com/guides/interoperability/#theme
// Material-UI has a rich theme structure that you can leverage for the color manipulations, the transitions, the media queries, and more.
// We encourage to share the same theme object between Material-UI and your styles.

const ThemeProvider: React.FC = ({ children }) => (
  <MuiThemeProvider theme={theme}>
    <SCThemeProvider theme={theme}>{children}</SCThemeProvider>
  </MuiThemeProvider>
)

export default ThemeProvider
