import { publicRuntimeConfig } from './config'

/**
 * An object containing the core configuration
 * info for Auth0.
 */
export const auth0Config = {
  scope: 'openid profile email',
  audience: publicRuntimeConfig.NEXT_PUBLIC_AUTH0_API_AUDIENCE!,
  client_id: publicRuntimeConfig.NEXT_PUBLIC_AUTH0_CLIENT_ID!,
  domain:
    publicRuntimeConfig.NEXT_PUBLIC_AUTH0_CUSTOM_DOMAIN ||
    publicRuntimeConfig.NEXT_PUBLIC_AUTH0_DOMAIN,
}
