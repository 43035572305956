import { createContext } from 'react'
import { LoginWithEmailCodeResponse } from '../utils/auth0'

type GenerateEmailAuthCodeResponseSuccess = { success: true; email: string; errorMessage?: never }
type GenerateEmailAuthCodeResponseFailure = { success: false; email: string; errorMessage?: string }
export type GenerateEmailAuthCodeResponse =
  | GenerateEmailAuthCodeResponseSuccess
  | GenerateEmailAuthCodeResponseFailure

export type Auth0Info = {
  accessToken: string
  scope: string
  expiresIn: number
  tokenType: string
  state: string
  idToken: string
  refreshToken: string
  idTokenPayload: {
    sub: string
    email: string
  }
}

export type Auth0ContextInterface = {
  generateEmailAuthCode: (
    email: string,
    scholarshipApplicationId?: string
  ) => Promise<GenerateEmailAuthCodeResponse>
  loginWithEmailAuthCode: (
    email: string,
    verificationCode: string,
    redirectUrl?: string
  ) => Promise<LoginWithEmailCodeResponse>
  setAuthInfo: () => Promise<void>
  isAuthenticated: boolean
  logout: (args?: { returnTo?: string }) => void
  isLoading: boolean
  login: (args?: {
    disableCloseButton?: boolean
    footerMessage?: string
    redirectUrl?: string
  }) => void
  closeModal: () => void
}
const stub = (): never => {
  throw new Error('You forgot to wrap your component in <AuthContext>.')
}

const initialContext = {
  generateEmailAuthCode: stub,
  loginWithEmailAuthCode: stub,
  setAuthInfo: stub,
  isAuthenticated: false,
  logout: stub,
  isLoading: false,
  login: stub,
  closeModal: stub,
}

const Auth0Context = createContext<Auth0ContextInterface>(initialContext)
export default Auth0Context
