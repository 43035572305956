import React, { memo } from 'react'
import { Slide } from '@material-ui/core'

import { TransitionProps } from '@material-ui/core/transitions'

import * as S from './Login.styles'
import LoginForm from './components/LoginForm/LoginForm'

export type Props = {
  showModal: boolean
  setShowModal?: React.Dispatch<React.SetStateAction<boolean>>
  footerMessage?: string
  disableCloseButton?: boolean
  redirectUrl?: string
  scholarshipApplicationId?: string
  showStepper?: boolean
  onLogginSuccess?: () => void
  modalTitle?: string
  maxWidth?: number
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" timeout={400} ref={ref} {...props} />
})

const Login: React.FC<Props> = (props) => {
  const { showModal, setShowModal } = props

  const handleCloseModal = () => {
    if (setShowModal) {
      setShowModal(false)
    }
  }

  return (
    <S.Dialog
      open={showModal}
      TransitionComponent={Transition}
      keepMounted
      onClose={(_, reason) => {
        if (reason === 'backdropClick') {
          return
        }

        handleCloseModal()
      }}
      aria-describedby="alert-dialog-slide-description"
    >
      <LoginForm {...props} handleCloseModal={handleCloseModal} />
    </S.Dialog>
  )
}

export default memo(Login)
