import { useCallback, useRef, useState } from 'react'
import SoundContext, { VideoState, StateMap, VideoRef } from './SoundContext'

export type Props = {
  children: React.ReactNode
}

const SoundProvider: React.FC<Props> = ({ children }: Props) => {
  const state = useRef<StateMap>(new Map<VideoRef, VideoState>())

  const [playingVideo, setPlayingVideo] = useState<VideoState | null>(null)

  const registerVideo = useCallback(
    (videoRef: VideoRef, videoState?: VideoState) => {
      if (!state.current.has(videoRef)) {
        state.current.set(videoRef, { ref: videoRef, muted: true, ...videoState })
      }
    },
    [state]
  )

  const unRegisterVideo = useCallback(
    (videoRef: VideoRef) => {
      if (state.current.has(videoRef)) {
        state.current.delete(videoRef)
      }
    },
    [state]
  )

  const notifyPlayEvent = useCallback((videoRef: VideoRef) => {
    let isVideoUnMuted = false
    state.current.forEach((value, key) => {
      if (key === videoRef) {
        state.current.set(key, { ...value, muted: false })
        setPlayingVideo({ ...value, muted: false })
        isVideoUnMuted = true
      } else {
        state.current.set(key, { ...value, muted: true })
      }
    })
    if (!isVideoUnMuted) {
      setPlayingVideo(null)
    }
  }, [])

  return (
    <SoundContext.Provider
      value={{
        state: state.current,
        playingVideo,
        notifyPlayEvent,
        registerVideo,
        unRegisterVideo,
      }}
    >
      {children}
    </SoundContext.Provider>
  )
}

export default SoundProvider
