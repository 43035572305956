import styled from 'styled-components'
import { SocialIcon as CustomSocialIcon } from 'react-social-icons'

export const Footer = styled.footer`
  width: 100%;
  margin-top: 45px;
  padding: 20px 30px 20px 30px;

  ${({ theme }) => theme.breakpoints.down('md')} {
    display: none;
  }
`

export const List = styled.ul`
  columns: 2;
  list-style-type: none;
  padding: 0;
  margin: 0;

  ${({ theme }) => theme.breakpoints.up('sm')} {
    columns: auto;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
  }
`

export const Item = styled.li`
  width: 160px;
  margin-bottom: 20px;
  ${({ theme }) => theme.breakpoints.up('sm')} {
    width: auto;
    display: block;
    padding-right: 60px;
  }
  &:last-child {
    padding-right: 0px;
  }
`

export const Link = styled.a`
  text-decoration: none;
  color: ${({ theme }) => theme.color.black};
  &:hover {
    text-decoration: underline;
  }
`

export const CopyrightContainer = styled.div`
  margin-top: 20px;
  ${({ theme }) => theme.breakpoints.up('sm')} {
    margin-top: 0px;
  }
`

export const DownloadOnTheAppStore = styled.img`
  width: 150px;
  height: 40px;
`

export const SocialIcon = styled(CustomSocialIcon)`
  width: 35px !important;
  height: 35px !important;
  margin-right: 5px;
`

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    flex-direction: column;
    align-items: normal;
  }
`

export const SocialIconContainer = styled.div`
  display: flex;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    margin-top: 20px;
  }
`
