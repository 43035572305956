import styled, { css } from 'styled-components'
import { Dialog as MuiDialog, Button as MuiButton } from '@material-ui/core'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import MuiCloseIcon from '@material-ui/icons/Close'

export const Container = styled.div<{ maxWidth?: number }>`
  width: 100%;

  &:focus-visible {
    outline: none;
  }

  ${({ maxWidth }) =>
    !!maxWidth &&
    css`
      width: ${maxWidth}px;
    `}

  ${({ theme }) => theme.breakpoints.down('xs')} {
    width: 100%;
  }
`

export const Header = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid gray;
  padding: 15px 10px;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    padding: 17px 10px;
  }
`

export const BackIcon = styled(ArrowBackIcon)`
  color: ${({ theme }) => theme.color.gray100};
`

export const CloseIcon = styled(MuiCloseIcon)`
  color: ${({ theme }) => theme.color.gray100};
`

export const Dialog = styled(MuiDialog)<{ maxSize?: number }>`
  .MuiBackdrop-root {
    background: rgb(34 34 34 / 85%) !important;
  }

  ${({ theme }) => theme.breakpoints.down('xs')} {
    .MuiDialog-paper {
      ${({ maxSize }) =>
        !!maxSize &&
        css`
          margin: 0px;
          height: 100vh;
        `}
    }
    .MuiDialog-paperScrollPaper {
      ${({ maxSize }) =>
        !!maxSize &&
        css`
          max-height: 100%;
        `}
    }
  }
`

export const BackButton = styled(MuiButton)`
  padding: 12px 0;
  margin-left: 5px;
  background-color: transparent !important;
  min-width: 45px;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    margin-left: 0;
  }
`
