import { memo } from 'react'

import theme from '../theme'
import { FontWeight, Variant } from './Typography.config'
import * as S from './Typography.styles'

export type Color = 'primary' | 'secondary' | 'disabled' | keyof typeof theme['font']['color']

export type BreakpointOptions = typeof theme.breakpoints.keys[0]
export type VariantType = keyof typeof Variant
export type VariantWeight = {
  variant: keyof typeof Variant
  weight?: FontWeight
}
export type ResponsiveVariantType = Partial<
  Record<BreakpointOptions, keyof typeof Variant | VariantWeight>
>

export type VariantProps =
  | {
      variant: VariantType
      responsiveVariant?: never
    }
  | {
      variant?: never
      responsiveVariant: ResponsiveVariantType
    }

export type Props = {
  color?: Color
  children: React.ReactNode
  uppercase?: boolean
  weight?: FontWeight
  component?: string | React.ComponentType
  className?: string
} & VariantProps

const Typography: React.FC<Props> = ({
  variant,
  responsiveVariant,
  color,
  children,
  uppercase,
  weight,
  component = 'span',
}) => {
  if (variant) {
    return (
      <S.Color as={component} color={color} variant={variant} uppercase={uppercase} weight={weight}>
        {children}
      </S.Color>
    )
  }

  if (responsiveVariant) {
    return (
      <S.ResponsiveVariant
        as={component}
        color={color}
        variant={responsiveVariant}
        uppercase={uppercase}
        weight={weight}
      >
        {children}
      </S.ResponsiveVariant>
    )
  }

  return null
}

export default memo(Typography)
