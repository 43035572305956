import { PublicFeaturedFlagFragment } from '@generated/graphql'
import { createContext } from 'react'

export type FeatureFlagContextInterface = {
  featureFlags?: PublicFeaturedFlagFragment[] | undefined
}

const initialContext = {
  featureFlags: undefined,
}

const FeatureFlagContext = createContext<FeatureFlagContextInterface>(initialContext)
export default FeatureFlagContext
