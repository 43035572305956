import * as auth0 from 'auth0-js'
import client from '../../../apollo/cognitoClient'
import { RefreshTokenMutation } from '@generated/graphql'
import { REFRESH_TOKEN } from '@modules/AuthProvider/AuthProvider.graphql'

import AuthStorageManager, { PUBLIC_STORAGE_KEY } from '../AuthStorageManager'
import { sign } from 'jsonwebtoken'

export type LoginWithEmailCodeResponse = {
  error?: {
    message?: string
  }
  success: boolean
  errorMessage?: string
}

let tokenRefreshTimer: ReturnType<typeof setInterval> | null = null
/**
 * Clears the user token and closes the auth session.
 * @param args - options for logging out.
 */
export const cognitologout = (args?: auth0.LogoutOptions): void => {
  tokenRefreshTimer && clearInterval(tokenRefreshTimer)
  tokenRefreshTimer = null
  AuthStorageManager.clear()
  window.location.href = '/'
}

/**
 * Attempts to refresh the auth token, and
 * @returns A promised boolean indicating whether the token was successfully refreshed or not.
 */
export const cognitorefreshToken = (): Promise<boolean> => {
  return new Promise((resolve) => {
    const store = JSON.parse(AuthStorageManager.get()?.toString()! || '{}')
    if (store.refreshToken) {
      client
        .mutate<RefreshTokenMutation>({
          mutation: REFRESH_TOKEN,
          variables: { refreshToken: store.refreshToken },
          fetchPolicy: 'no-cache',
        })
        .then((response) => {
          if (!response.data?.refreshToken) {
            resolve(false)
            return null
          }
          if (response.data?.refreshToken.__typename === 'RefreshTokenResponse') {
            AuthStorageManager.set(
              JSON.stringify({ ...response.data.refreshToken, refreshToken: store.refreshToken })
            )
            resolve(true)
          }
        })
    } else {
      resolve(false)
    }
  })
}

export const getAccessToken = async (): Promise<string | null> => {
  let accessToken: string | null =
    (await getUserToken()) ||
    JSON.parse(AuthStorageManager.get()?.toString()! || '{}').accessToken ||
    null

  if (!accessToken) {
    accessToken = sign({ sub: 'public', service: 'web' }, 'public')
    AuthStorageManager.set({ accessToken }, PUBLIC_STORAGE_KEY)
  }

  return accessToken
}

export const getUserToken = async (): Promise<string | null> => {
  const store = JSON.parse(AuthStorageManager.get()?.toString()! || '{}')
  let userToken: string | null = store?.accessToken || null
  if (userToken && isTokenExpired(userToken)) {
    await cognitorefreshToken()
    userToken = store.accessToken || null
  }
  return userToken
}

const isTokenExpired = (token: string): boolean => {
  try {
    const exp = JSON.parse(atob(token.split('.')[1]))?.exp
    return !Number.isNaN(Number(exp)) && Date.now() >= exp * 1000 ? true : false
  } catch {
    return false
  }
}

/**
 * Begins the token refresh timer.
 * @param intervalInSeconds - the number of seconds at which the token should be refreshed.
 * @returns void
 */
export const startTokenRefreshTimer = (intervalInSeconds: number): void => {
  // Take the expiration time and subtract 10 seconds.
  // Within this time frame ↑, attempt to refresh the token
  // up to 4 times. When a token is successfully refreshed, the
  // timer will restart - otherwise, it will continue and attempt
  // to refresh the token up to 3 additional times.
  // This way, we should be able to account for the network connection
  // being lost during any of these attempts.
  const bufferOf30Seconds = 1000 * 30
  const interval = (intervalInSeconds * 1000 - bufferOf30Seconds) / 4
  tokenRefreshTimer && clearInterval(tokenRefreshTimer)
  tokenRefreshTimer = setInterval(async () => {
    await cognitorefreshToken()
  }, interval)
}
