import { AppBar as MuiAppBar } from '@material-ui/core'
import styled, { css } from 'styled-components'
import ButtonLink from '@components/LegacyButtonLink/LegacyButtonLink'
import MuiSimpleAcknowledgementAlert, {
  Props as MuiSimpleAcknowledgementAlertProps,
} from '@components/SimpleAcknowledgementAlert/SimpleAcknowledgementAlert'

export const SimpleAcknowledgementAlert = styled(
  MuiSimpleAcknowledgementAlert
)<MuiSimpleAcknowledgementAlertProps>`
  padding-top: 0px;
  padding-bottom: 0px;
`

export const AppBar = styled(MuiAppBar)`
  box-shadow: none;
  .MuiToolbar-regular {
    min-height: 64px;
  }
  position: sticky;
  top: 0px;
  z-index: 2;
`

export const Logo = styled.div<{ logoColor: 'blue' | 'white' | 'darkGray' }>`
  font-family: ${({ theme }) => theme.font.family.almarenaDisplay};
  font-size: ${({ theme }) => theme.font.size.xlarge};
  font-weight: ${({ theme }) => theme.font.weight.bold};
  line-height: 1;
  text-transform: uppercase;
  margin-top: 2px;
  ${({ theme }) => theme.breakpoints.down('xs')} {
    font-size: ${({ theme }) => theme.font.size.large};
  }
  ${({ logoColor }) =>
    logoColor === 'blue' &&
    css`
      color: ${({ theme }) => theme.color.purple};
    `}
  ${({ logoColor }) =>
    logoColor === 'white' &&
    css`
      color: ${({ theme }) => theme.color.white};
    `}
  ${({ logoColor }) =>
    logoColor === 'darkGray' &&
    css`
      color: ${({ theme }) => theme.color.darkGray};
    `}
`

export const MenuBackground = styled.div<{ isMenuVisible?: boolean }>`
  position: absolute;
  width: 0;
  height: 0;
  border-radius: 50%;
  background: ${({ theme }) => theme.color.carbon};
  pointer-events: none;
  transition: 0.4s;
  right: 40px;
  top: 33px;
  transform: translate3d(50%, -50%, 0);
  transform-origin: center center;
  z-index: 4;
  ${({ theme }) => theme.breakpoints.down('xs')} {
    transition: 0.3s;
    right: 30px;
  }
`

export const Link = styled(ButtonLink)`
  justify-content: center;
  margin: 0px;
  padding: 0;
  &:hover {
    background: none;
  }
`

export const PrimaryLink = styled.a`
  margin-right: 20px;
  text-decoration: none;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`

export const Items = styled.div<{ isMenuVisible?: boolean }>`
  display: flex;
  ${({ theme }) => theme.breakpoints.down('md')} {
    ${({ isMenuVisible }) =>
      !isMenuVisible &&
      css`
        display: none;
      `};
  }
`
export const Container = styled.div`
  position: sticky;
  top: 0px;
  z-index: 4;
  background: ${({ theme }) => theme.color.cultured};
`
