import Script from 'next/script'

const GoogleTagManagerScript: React.FC = () => {
  return (
    <>
      <Script async src="https://www.googletagmanager.com/gtag/js?id=G-VDPJDS1FQP"></Script>
      <Script
        strategy="lazyOnload"
        dangerouslySetInnerHTML={{
          __html: `window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments);} gtag('js', new Date()); gtag('config', 'G-VDPJDS1FQP');gtag('config', 'AW-10841758414');
          `,
        }}
      ></Script>
    </>
  )
}

export default GoogleTagManagerScript
