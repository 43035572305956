import { Button as MuiButton } from '@material-ui/core'
import styled, { css } from 'styled-components'
import { ButtonVariant, ButtonSize, styleButtonSize } from './Button.config'

// buttonvariant and buttonsize are in lowercase because React does not recognize them as props on DOM element
// to avoid this we can use shouldForwardProp https://styled-components.com/docs/api#shouldforwardprop
// this iterates all props and excludes them. This operation will create an unnecessary overhead
export const Button = styled(MuiButton)<{ buttonvariant?: ButtonVariant; buttonsize: ButtonSize }>`
  ${(props) =>
    css`
      ${styleButtonSize[props.buttonsize]}
    `}

  text-transform: none;

  ${(props) =>
    props.buttonvariant === 'primary' &&
    css`
      transition: all 0.2s ease-out;
      box-shadow: none;
      border-radius: 200px;
      line-height: unset;
      letter-spacing: unset;
      color: ${({ theme }) => theme.color.white};
      background: ${({ theme }) => `${theme.color.purple}`};

      &:active {
        background-color: ${({ theme }) => theme.color.purple};
        color: ${({ theme }) => theme.color.carbon};
      }

      &:hover {
        color: ${({ theme }) => theme.color.white};
        box-shadow: none;
        background: ${({ theme }) => theme.color.darkPurple10};
        opacity: 0.9;
      }
      &:disabled {
        background: ${({ theme }) => theme.color.gray05};
        color: ${({ theme }) => theme.color.gray60};
      }

      background-size: 205% 100%;
      background-position: right bottom;
    `}

  ${(props) =>
    props.buttonvariant === 'secondary' &&
    css`
      transition: all 0.2s ease-out;
      box-shadow: none;
      border-radius: 200px;
      line-height: unset;
      letter-spacing: unset;
      color: ${({ theme }) => theme.color.sanMarino};
      border: 1px solid ${({ theme }) => theme.color.sanMarino};
      background: ${({ theme }) =>
        `linear-gradient(to right, ${theme.color.antiFlashWhite} 50%, ${theme.color.white} 50% )`};

      &:active {
        background-color: ${({ theme }) => theme.color.white};
        color: ${({ theme }) => theme.color.sanMarino};
      }

      &:hover {
        color: ${({ theme }) => theme.color.sanMarino};
        background-position: left bottom;
        box-shadow: none;
      }

      &:disabled {
        border: 1px solid ${({ theme }) => theme.color.gray40};
        background: ${({ theme }) => theme.color.white};
        color: ${({ theme }) => theme.color.gray60};
      }

      ${({ theme }) => theme.breakpoints.down('xs')} {
        background: ${({ theme }) => theme.color.white};
        &:active,
        &:hover {
          background: ${({ theme }) => theme.color.antiFlashWhite};
          background-position: unset;
          box-shadow: none;
          color: ${({ theme }) => theme.color.sanMarino};
        }
      }
      background-size: 205% 100%;
      background-position: right bottom;
    `}
`
