export const AUTH0_STORAGE_KEY_NAME = 'auth0Cypress'

export type Auth0Cypress = {
  token: string
  sub: string
  email: string
}

export const getAuthCypress: () => Auth0Cypress | null = () => {
  let auth0Token: Auth0Cypress | null
  try {
    const auth0TokenStored = localStorage.getItem(AUTH0_STORAGE_KEY_NAME)

    auth0Token = auth0TokenStored ? JSON.parse(auth0TokenStored) : null
  } catch {
    auth0Token = null
  }

  return auth0Token
}
