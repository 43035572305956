import { useState, memo } from 'react'
import { Box } from '@material-ui/core'
import { publicRuntimeConfig } from '../../../../config/config'

import { loginWithGoogle } from '@modules/AuthProvider/utils/auth0'
import Typography from '@components/Typography/Typography'
import * as S from './EmailForm.styles'

export type Props = {
  onEmailSubmit: (email: string) => void
  error: string
  setError: React.Dispatch<React.SetStateAction<string>>
  redirectUrl?: string
}

const EmailForm: React.FC<Props> = ({ onEmailSubmit, error, setError, redirectUrl }) => {
  const [email, setEmail] = useState<string>('')
  const isCognitoClient = publicRuntimeConfig.NEXT_PUBLIC_COGNITO_AUTH_ENABLED

  return (
    <S.FormContainer>
      <Typography variant="H300">ENTER YOUR EMAIL ADDRESS</Typography>
      <S.TextField
        variant="outlined"
        type="email"
        value={email}
        placeholder={'Email Address'}
        onChange={(e) => {
          setEmail(e.target.value)
          setError('')
        }}
      />
      {!!error && <S.ErrorMessage>{error}</S.ErrorMessage>}
      <Box mt="20px" width={1}>
        <S.NextButton
          type="submit"
          variant="primary"
          size="large"
          onClick={() => {
            onEmailSubmit(email)
          }}
          endIcon="&#x2192;"
          disabled={!email}
        >
          <Typography variant="H300">NEXT</Typography>
        </S.NextButton>
      </Box>
      {!isCognitoClient && (
        <Box mt="30px" width={1}>
          <Box display="flex" alignItems="center" mb="20px" width={1}>
            <S.Separator />
            <Typography variant="H300" color="gray40">
              OR
            </Typography>
            <S.Separator />
          </Box>
          <Box display="flex" justifyContent="center">
            <S.GoogleButton
              type="light"
              label="CONTINUE WITH GOOGLE"
              onClick={() => {
                loginWithGoogle(redirectUrl)
              }}
            />
          </Box>
        </Box>
      )}
      <Box marginTop="15px">
        <Typography variant="BODY_MEDIUM" color="disabledDarkContrast">
          By tapping “next” you are agreeing to our{' '}
          <a
            href="https://protege.notion.site/Terms-of-Use-dfb4e88eda9d4ac599ed0e1a49ee382e"
            target="_blank"
            rel="noreferrer"
          >
            Terms of Service
          </a>{' '}
          and{' '}
          <a
            href="https://protege.notion.site/Privacy-Policy-471b0637e5ea4a9b987d2ce2051325f8"
            target="_blank"
            rel="noreferrer"
          >
            Privacy Policy
          </a>
          .
        </Typography>
      </Box>
    </S.FormContainer>
  )
}

export default memo(EmailForm)
