export enum EventNameEnum {
  System = 'System',
  Action = 'Action',
}

export enum EventTypeEnum {
  PageView = 'Page View',
  Click = 'Click',
  VideoUpload = 'Video Upload',
  VideoPlay = 'Video Play',
  SubmitApplication = 'Submit Application',
  PayApplication = 'Pay Application',
}

export enum VideoTypeEnum {
  Audition = 'Audition',
  Feedback = 'Feedback',
}

export enum CurrentPathTypeEnum {
  Feed = 'Feed',
  Discovery = 'Discovery',
  ExpertPage = 'Expert Page',
  CreateApplicationPage = 'Create Application Page',
  EditApplicationPage = 'Edit Application Page',
  ApplicationDetailPage = 'Application Detail Page',
  StartApplication = 'Start Application',
  ChooseFile = 'Choose File',
  FileChosen = 'FileChosen',
  StartUpload = 'Start Upload',
  FinishUpload = 'Finish Upload',
  ContinueToProfile = 'Continue To Profile',
  ProfilePage = 'Profile Page',
  UpdateProfile = 'Update Profile',
  ContinueToSubmit = 'Continue To Submit',
  SummaryAndPayment = 'Summary and Payment',
  SubmitApplication = 'Submit Application',
  ApplicationInProfile = 'Application In Profile',
  ConversationInProfile = 'Conversation In Profile',
  AllApplications = 'All Applications',
  SocialInfoPage = 'Social Info Page',
  SocialCommentPage = 'Social Comment Page',
  SocialPostPage = 'Social Post Page',
  HomePage = 'Home Page',
  HousePage = 'House Page',
  MissionPage = 'Mission Page',
  RecommendationsPage = 'Recommendations Page',
  WalletPage = 'Wallet Page',
  CountdownPage = 'Countdown Page',
  PageNotFound = 'Page Not Found',
  ShareModal = 'Share Modal',
  ShareModalAfterSubmitting = 'Share Modal After Submitting ',
  FeedbackPage = 'Application Feedback Page',
  ScholarshipAudition = 'Scholarship Audition',
  FillScreen = 'Fill Screen on Conversation Page',
  CreateApplicationSummaryAndPayment = 'Create Application, Summary and Payment',
  CategoryPage = 'Category Page',
  GoogleAuth = 'Google Auth',
  EmailAuth = 'Email Auth',
  Menu = 'Menu',
  CategoryModal = 'Category Modal',
  CreateReferralStudent = 'Create Referral Student',
  ReferralStudentEmailSignUp = 'Referral Student Email SignUp',
  ReferralStudentTalentQuestion = 'Referral Student Talent Question',
  ReferralStudentUploadScreen = 'Referral Student Upload Screen',
  ReferralStudentAccountCompleted = 'Referral Student Account Completed',
  ReferralStudentNoTalent = 'Referral Student No Talent',
  ReferralStudentExistingUser = 'Referral Student Existing User',
  LandingPage = 'Landing Page',
  ConversationsPage = 'Conversations Page',
  ConversationPage = 'Conversation Page',
}

export enum GtagConversionTypeEnum {
  WebsiteSale = 'AW-10841758414/RFtVCLHN1pEDEM614LEo',
  ApplicationStart = 'AW-10841758414/gZDBCMeD98kDEM614LEo',
}

type TrackGtagConversionType = {
  type: GtagConversionTypeEnum
  category?: string
  transactionId?: string
  value?: number
  properties?: Record<string, unknown>
}

type TrackPropertiesType = {
  type: EventTypeEnum
  description: string
  currentPathType: CurrentPathTypeEnum
  properties?: Record<string, unknown>
  purchases?: {
    product_id: string
    name: string
    price: number
    url: string
  }[]
  gtagConversion?: TrackGtagConversionType
}

const regexMobileDevices = new RegExp(/iPhone|iPad|iPod|Android/, 'i')

export const trackEvent = (event: TrackPropertiesType): void => {
  const properties = {
    event_type: event.type,
    event_details: event.description,
    ...(location && {
      event_url: location.href,
      current_path: location.pathname,
    }),
    current_path_type: event.currentPathType,
    page_details: {
      ...event.properties,
      device_type: navigator && regexMobileDevices.test(navigator.userAgent) ? 'Mobile' : 'Desktop',
    },
    ...(document && {
      previous_path: document.referrer,
    }),
    ...(event.purchases && {
      purchases: event.purchases,
    }),
  }

  analytics.track(EventNameEnum.Action, properties)

  if (event.gtagConversion) trackGtagConversion(event.gtagConversion)
}

export const useTrackNavigationEvent = (
  eventDetails: Partial<Omit<TrackPropertiesType, 'type' | 'description'>> & {
    currentPathType: CurrentPathTypeEnum
  },
  loading = false
): void => {
  if (typeof window === 'undefined' || loading) return

  trackEvent({
    type: EventTypeEnum.PageView,
    description: 'Page Loaded',
    ...eventDetails,
  })
}

export const trackGtagConversion = (gtagConversion: TrackGtagConversionType): void => {
  if (typeof window !== 'undefined' && typeof window.gtag !== 'undefined') {
    gtag('event', 'conversion', {
      send_to: gtagConversion.type,
      ...(gtagConversion.category && { event_category: gtagConversion.category }),
      transaction_id: gtagConversion.transactionId,
      ...(gtagConversion.value && { value: gtagConversion.value }),
      ...gtagConversion.properties,
    })
  }
}

export const getAnonymousId = (): string | null => {
  const analyticsUser =
    typeof window !== 'undefined' &&
    typeof analytics !== 'undefined' &&
    typeof analytics.user === 'function'
      ? analytics.user()
      : null

  return typeof analyticsUser?.anonymousId === 'function' ? analyticsUser.anonymousId() : null
}
