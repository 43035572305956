
      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "ApplicationRefundResponse": [
      "ApplicationRefundErrorResponse",
      "PrivilegedApplication"
    ],
    "CompleteScholarshipApplicationReferralResponseUnion": [
      "CompleteScholarshipApplicationReferralErrorResponse",
      "PrivilegedScholarshipApplicationReferral"
    ],
    "ConversationEntityResponseUnion": [
      "PrivilegedCohort"
    ],
    "CreateApplicationResponse": [
      "CreateApplicationErrorResponse",
      "ManagementReferralCodeErrorResponse",
      "PrivilegedApplication"
    ],
    "CredentialAssignmentEntityType": [
      "HouseCredentialAssignmentEntityType",
      "PublicCohort",
      "PublicUser"
    ],
    "IReview": [
      "PrivilegedReview",
      "PublicReview"
    ],
    "IThreadItem": [
      "PrivilegedThreadItem",
      "PublicThreadItem"
    ],
    "MessageEntityResponseUnion": [
      "Media",
      "PrivilegedApplication",
      "PrivilegedReview"
    ],
    "PurchaseApplicationResponseUnion": [
      "PrivilegedApplication",
      "PurchaseApplicationErrorResponse"
    ],
    "RedeemReferralCodeResponseUnion": [
      "ManagementReferralCodeErrorResponse",
      "PrivilegedApplication"
    ],
    "RefreshTokenResponseUnion": [
      "AuthErrorResponse",
      "RefreshTokenResponse"
    ],
    "ScholarshipToPaidApplicationResponseUnion": [
      "PrivilegedApplication",
      "ScholarshipToPaidApplicationErrorResponse"
    ],
    "ScholarshipUpvoteResponse": [
      "PrivilegedApplication",
      "ScholarshipUpvoteErrorResponse"
    ],
    "TaggedEntityUnion": [
      "PublicApplication",
      "PublicCohort",
      "PublicInterstitialAd",
      "PublicUser"
    ],
    "ThreadEntityUnion": [
      "PublicReview"
    ],
    "UpdateUserResponse": [
      "CurrentUser",
      "UpdateUserErrorResponse"
    ],
    "VerifyAuthResponseUnion": [
      "AuthErrorResponse",
      "VerifyAuthResponse"
    ]
  }
};
      export default result;
    