import { memo, useState } from 'react'

import SimpleStepper from '@components/SimpleStepper/SimpleStepper'
import Typography from '@components/Typography/Typography'
import EmailForm from '../EmailForm/EmailForm'
import SecurityCodeForm from '../SecurityCodeForm/SecurityCodeForm'
import { Box, IconButton } from '@material-ui/core'

import * as S from './LoginForm.styles'
import { validateEmail } from '@modules/utils'
import { useAuth } from '@modules/AuthProvider/useAuth'
import * as Sentry from '@sentry/nextjs'

export type Props = {
  footerMessage?: string
  disableCloseButton?: boolean
  redirectUrl?: string
  scholarshipApplicationId?: string
  showStepper?: boolean
  onLogginSuccess?: () => void
  modalTitle?: string
  embeddedLogin?: boolean
  handleCloseModal?: () => void
  maxWidth?: number
}

const LoginForm: React.FC<Props> = ({
  footerMessage,
  disableCloseButton,
  scholarshipApplicationId,
  redirectUrl,
  showStepper,
  onLogginSuccess,
  handleCloseModal,
  modalTitle = 'Log in or sign up',
  maxWidth,
}) => {
  const { generateEmailAuthCode, loginWithEmailAuthCode } = useAuth()
  const [showBack, setShowBack] = useState<boolean>(false)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<string>('')
  const [email, setEmail] = useState<string>('')

  const handleClose = () => {
    setEmail('')
    setShowBack(false)
    setError('')
    setLoading(false)
    if (handleCloseModal) handleCloseModal()
  }

  const sendCodeToEmail = async (email) => {
    if (validateEmail(email)) {
      try {
        setError('')
        generateEmailAuthCode(email, scholarshipApplicationId)
        setEmail(email)
        setShowBack(true)
      } catch (error) {
        Sentry.captureException(error)
      }
    } else {
      setError('Please enter a valid email')
    }
  }

  const validateCode = async (code) => {
    setError('')
    setLoading(true)
    loginWithEmailAuthCode(email, code, redirectUrl)
      .then((res) => {
        if (res.error || res.errorMessage) {
          Sentry.captureMessage(
            `[Login Code Validation Error]: ${res?.error?.message || res.errorMessage}`,
            Sentry.Severity.Warning
          )
          setError(res?.error?.message! || res.errorMessage!)
        }
        if (onLogginSuccess) {
          onLogginSuccess()
        }
      })
      .catch((error) => {
        setError(
          'An error occurred validating the access code. Please reach out to us at support@protege.app so we can help.'
        )
        Sentry.captureException(error)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return (
    <S.Container maxWidth={maxWidth}>
      <S.Header>
        {showBack && (
          <Box position="absolute" left={6} top={7}>
            <S.BackButton
              aria-label="close"
              onClick={() => {
                setEmail('')
                setError('')
                setShowBack(false)
              }}
            >
              <S.BackIcon />
              <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                <Typography variant="H300">&nbsp;&nbsp;Back</Typography>
              </Box>
            </S.BackButton>
          </Box>
        )}
        <Typography responsiveVariant={{ xs: 'H400', sm: 'H500' }} weight="bold">
          {modalTitle}
        </Typography>
        {!disableCloseButton && (
          <Box position="absolute" right={6} top={7}>
            <IconButton
              aria-label="close"
              onClick={handleClose}
              style={{
                backgroundColor: 'transparent',
              }}
            >
              <S.CloseIcon />
            </IconButton>
          </Box>
        )}
      </S.Header>
      <div>
        {!!showStepper && (
          <Box mr={'15px'} mb={'-10px'}>
            <SimpleStepper currentStep={1} />
          </Box>
        )}
        {!email && (
          <EmailForm
            onEmailSubmit={sendCodeToEmail}
            error={error}
            setError={setError}
            redirectUrl={redirectUrl}
          />
        )}
        {!!email && (
          <SecurityCodeForm onValidateCode={validateCode} error={error} loading={loading} />
        )}
        {!!footerMessage && (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            textAlign="center"
            mx="15px"
            marginBottom="15px"
          >
            <Typography variant="BODY_MEDIUM">{footerMessage}</Typography>
          </Box>
        )}
      </div>
    </S.Container>
  )
}

export default memo(LoginForm)
