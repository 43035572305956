import getConfig from 'next/config'

export enum ExpertPageVariant {
  DEFAULT = 'DEFAULT',
  RAISE = 'RAISE',
}

export enum HomePageVariant {
  RAISE = 'RAISE',
}

type PublicRuntimeConfig = {
  NEXT_PUBLIC_ANALYTICS_WRITE_KEY: string
  NEXT_PUBLIC_GRAPHQL_API_URL: string
  NEXT_PUBLIC_AUTH0_DOMAIN: string
  NEXT_PUBLIC_AUTH0_CUSTOM_DOMAIN?: string
  NEXT_PUBLIC_AUTH0_CLIENT_ID: string
  NEXT_PUBLIC_AUTH0_BASE_URL: string
  NEXT_PUBLIC_AUTH0_API_AUDIENCE: string
  NEXT_PUBLIC_STRIPE_KEY: string
  NEXT_PUBLIC_CLOUDINARY_KEY: string
  NEXT_PUBLIC_CLOUDINARY_CLOUD_NAME: string
  NEXT_PUBLIC_CLOUDINARY_BASE_URL: string
  NEXT_PUBLIC_EXPERT_PAGE_VARIANT: ExpertPageVariant
  NEXT_PUBLIC_SITE_URL: string
  NEXT_PUBLIC_HOME_PAGE_VARIANT: HomePageVariant
  NEXT_PUBLIC_TYPEFORM_ID?: string
  NEXT_PUBLIC_PROTEGE_ENV?: string
  NEXT_PUBLIC_AR_EXPERT_NAME?: string
  NEXT_WAITLIST_EXPERT_ID?: string
  NEXT_PUBLIC_COGNITO_AUTH_ENABLED?: boolean
}

type ServerRuntimeConfig = {
  TWITTER_CLIENT_ID: string
  TWITTER_CLIENT_SECRET: string
}

const parseStringOrThrow = (value?: string): string => {
  if (!value) {
    throw new Error('Required env var not set')
  }

  return value
}

/**
 * Returns public runtime environment variables
 */
export const publicRuntimeConfig: PublicRuntimeConfig = {
  NEXT_PUBLIC_GRAPHQL_API_URL: parseStringOrThrow(
    getConfig().publicRuntimeConfig.NEXT_PUBLIC_GRAPHQL_API_URL
  ),
  NEXT_PUBLIC_AUTH0_DOMAIN: parseStringOrThrow(
    getConfig().publicRuntimeConfig.NEXT_PUBLIC_AUTH0_DOMAIN
  ),
  NEXT_PUBLIC_AUTH0_CUSTOM_DOMAIN: getConfig().publicRuntimeConfig.NEXT_PUBLIC_AUTH0_CUSTOM_DOMAIN,
  NEXT_PUBLIC_AUTH0_CLIENT_ID: parseStringOrThrow(
    getConfig().publicRuntimeConfig.NEXT_PUBLIC_AUTH0_CLIENT_ID
  ),
  NEXT_PUBLIC_AUTH0_BASE_URL: parseStringOrThrow(
    getConfig().publicRuntimeConfig.NEXT_PUBLIC_AUTH0_BASE_URL
  ),
  NEXT_PUBLIC_AUTH0_API_AUDIENCE: parseStringOrThrow(
    getConfig().publicRuntimeConfig.NEXT_PUBLIC_AUTH0_API_AUDIENCE
  ),
  NEXT_PUBLIC_STRIPE_KEY: parseStringOrThrow(
    getConfig().publicRuntimeConfig.NEXT_PUBLIC_STRIPE_KEY
  ),
  NEXT_PUBLIC_CLOUDINARY_KEY: parseStringOrThrow(
    getConfig().publicRuntimeConfig.NEXT_PUBLIC_CLOUDINARY_KEY
  ),
  NEXT_PUBLIC_CLOUDINARY_CLOUD_NAME: parseStringOrThrow(
    getConfig().publicRuntimeConfig.NEXT_PUBLIC_CLOUDINARY_CLOUD_NAME
  ),
  NEXT_PUBLIC_CLOUDINARY_BASE_URL: parseStringOrThrow(
    getConfig().publicRuntimeConfig.NEXT_PUBLIC_CLOUDINARY_BASE_URL
  ),
  NEXT_PUBLIC_ANALYTICS_WRITE_KEY: parseStringOrThrow(
    getConfig().publicRuntimeConfig.NEXT_PUBLIC_ANALYTICS_WRITE_KEY
  ),
  NEXT_PUBLIC_EXPERT_PAGE_VARIANT: getConfig().publicRuntimeConfig.NEXT_PUBLIC_EXPERT_PAGE_VARIANT,
  NEXT_PUBLIC_SITE_URL: parseStringOrThrow(getConfig().publicRuntimeConfig.NEXT_PUBLIC_SITE_URL),
  NEXT_PUBLIC_HOME_PAGE_VARIANT: getConfig().publicRuntimeConfig.NEXT_PUBLIC_HOME_PAGE_VARIANT,
  NEXT_PUBLIC_TYPEFORM_ID: getConfig().publicRuntimeConfig.NEXT_PUBLIC_TYPEFORM_ID,
  NEXT_PUBLIC_PROTEGE_ENV: getConfig().publicRuntimeConfig.NEXT_PUBLIC_PROTEGE_ENV,
  NEXT_PUBLIC_AR_EXPERT_NAME: getConfig().publicRuntimeConfig.NEXT_PUBLIC_AR_EXPERT_NAME,
  NEXT_WAITLIST_EXPERT_ID: getConfig().publicRuntimeConfig.NEXT_WAITLIST_EXPERT_ID,
  NEXT_PUBLIC_COGNITO_AUTH_ENABLED:
    getConfig().publicRuntimeConfig.NEXT_PUBLIC_COGNITO_AUTH_ENABLED === 'true' || false,
}

/**
 * Returns server runtime environment variables
 */
export const serverRuntimeConfig: ServerRuntimeConfig = {
  TWITTER_CLIENT_ID: getConfig().serverRuntimeConfig.TWITTER_CLIENT_ID,
  TWITTER_CLIENT_SECRET: getConfig().serverRuntimeConfig.TWITTER_CLIENT_SECRET,
}
