import { Variant } from '@components/Typography/Typography.config'

export type ButtonVariant = 'primary' | 'secondary'
export type ButtonSize = 'large' | 'medium' | 'small'

export type ButtonSizeConfiguration = {
  padding: React.CSSProperties['padding']
  ['.MuiButton-endIcon']: {
    marginLeft: React.CSSProperties['marginLeft']
    marginRight: React.CSSProperties['marginRight']
  }
  ['.MuiButton-startIcon']: {
    marginLeft: React.CSSProperties['marginLeft']
    marginRight: React.CSSProperties['marginRight']
  }
}

export const buttonSizeVariant: Record<ButtonSize, Variant> = {
  large: Variant.H300,
  medium: Variant.H200,
  small: Variant.H100,
}

export const styleButtonSize: Record<ButtonSize, ButtonSizeConfiguration> = {
  large: {
    padding: '12px 24px',
    ['.MuiButton-endIcon']: {
      marginLeft: '16px',
      marginRight: '0px',
    },
    ['.MuiButton-startIcon']: {
      marginLeft: '0px',
      marginRight: '16px',
    },
  },
  medium: {
    padding: '10px 20px',
    ['.MuiButton-endIcon']: {
      marginLeft: '10px',
      marginRight: '0px',
    },
    ['.MuiButton-startIcon']: {
      marginLeft: '0px',
      marginRight: '10px',
    },
  },
  small: {
    padding: '8px 12px',
    ['.MuiButton-endIcon']: {
      marginLeft: '8px',
      marginRight: '0px',
    },
    ['.MuiButton-startIcon']: {
      marginLeft: '0px',
      marginRight: '8px',
    },
  },
}
