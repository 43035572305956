import { memo, useEffect } from 'react'
import { useSegmentIdentifyViewerQuery } from '@generated/graphql'

const SegmentIdentify = () => {
  const { data } = useSegmentIdentifyViewerQuery({
    fetchPolicy: 'cache-first',
  })

  useEffect(() => {
    if (data?.viewer?.id) {
      window.analytics.identify(data.viewer.id, {
        name: data.viewer.name,
        username: data.viewer.username,
        email: data.viewer.email,
      })
    }
  })

  return <></>
}

export default memo(SegmentIdentify)
