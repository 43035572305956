import { createTheme, responsiveFontSizes, ThemeOptions } from '@material-ui/core/styles'
import React from 'react'

// https://material-ui.com/customization/default-theme/
// https://material-ui.com/guides/typescript/#customization-of-theme

declare module '@material-ui/core/styles/createTheme' {
  interface Theme {
    navigation: {
      height: number
    }
    chipStatus: {
      first: {
        backgroundColor: React.CSSProperties['backgroundColor']
        color: React.CSSProperties['color']
      }
      second: {
        backgroundColor: React.CSSProperties['backgroundColor']
        color: React.CSSProperties['color']
      }
      third: {
        backgroundColor: React.CSSProperties['backgroundColor']
        color: React.CSSProperties['color']
      }
      fourth: {
        backgroundColor: React.CSSProperties['backgroundColor']
        color: React.CSSProperties['color']
      }
      fifth: {
        backgroundColor: React.CSSProperties['backgroundColor']
        color: React.CSSProperties['color']
      }
      sixth: {
        backgroundColor: React.CSSProperties['backgroundColor']
        color: React.CSSProperties['color']
      }
    }
    border: {
      color: {
        light: React.CSSProperties['borderColor']
        dark: React.CSSProperties['borderColor']
        error: React.CSSProperties['borderColor']
        white: React.CSSProperties['borderColor']
        gray: React.CSSProperties['borderColor']
        carbon: React.CSSProperties['borderColor']
        lemon: React.CSSProperties['borderColor']
        lightCreme: React.CSSProperties['borderColor']
        lightGray: React.CSSProperties['borderColor']
      }
      radius: {
        small: React.CSSProperties['borderRadius']
        medium: React.CSSProperties['borderRadius']
        large: React.CSSProperties['borderRadius']
        xxl: React.CSSProperties['borderRadius']
        xxxl: React.CSSProperties['borderRadius']
        xxxxl: React.CSSProperties['borderRadius']
        xxxxxl: React.CSSProperties['borderRadius']
        circle: React.CSSProperties['borderRadius']
        mega: React.CSSProperties['borderRadius']
      }
      width: {
        small: React.CSSProperties['borderWidth']
        medium: React.CSSProperties['borderWidth']
        large: React.CSSProperties['borderWidth']
        xlarge: React.CSSProperties['borderWidth']
      }
    }
    font: {
      weight: {
        light: React.CSSProperties['fontWeight']
        regular: React.CSSProperties['fontWeight']
        medium: React.CSSProperties['fontWeight']
        bold: React.CSSProperties['fontWeight']
        extraBold: React.CSSProperties['fontWeight']
      }
      size: {
        supertiny: React.CSSProperties['fontSize']
        tiny: React.CSSProperties['fontSize']
        small: React.CSSProperties['fontSize']
        compact: React.CSSProperties['fontSize']
        medium: React.CSSProperties['fontSize']
        mediumLarge: React.CSSProperties['fontSize']
        large: React.CSSProperties['fontSize']
        regular: React.CSSProperties['fontSize']
        xlarge: React.CSSProperties['fontSize']
        xxlarge: React.CSSProperties['fontSize']
        xxxlarge: React.CSSProperties['fontSize']
        billboard: React.CSSProperties['fontSize']
        jumbo: React.CSSProperties['fontSize']
        mega: React.CSSProperties['fontSize']
      }
      family: {
        almarenaDisplay: React.CSSProperties['fontFamily']
        code: React.CSSProperties['fontFamily']
        novemberCompressedHeavy: React.CSSProperties['fontFamily']
        barlow: React.CSSProperties['fontFamily']
        barlowCondensed: React.CSSProperties['fontFamily']
        satoshi: React.CSSProperties['fontFamily']
      }
      color: {
        indigo: React.CSSProperties['color']
        green: React.CSSProperties['color']
        red: React.CSSProperties['color']
        gray: React.CSSProperties['color']
        blue: React.CSSProperties['color']
        black: React.CSSProperties['color']
        lemon: React.CSSProperties['color']
        darkerBlue: React.CSSProperties['color']
        graniteGray: React.CSSProperties['color']
        primaryDarkContrast: React.CSSProperties['color']
        secondaryDarkContrast: React.CSSProperties['color']
        disabledDarkContrast: React.CSSProperties['color']
        primaryLightContrast: React.CSSProperties['color']
        secondaryLightContrast: React.CSSProperties['color']
        disabledLightContrast: React.CSSProperties['color']
        white: React.CSSProperties['color']
        darkGray: React.CSSProperties['color']
        carbon: React.CSSProperties['color']
        lightGray: React.CSSProperties['color']
        gray64: React.CSSProperties['color']
        gray400: React.CSSProperties['color']
        gray100: React.CSSProperties['color']
        sanMarino: React.CSSProperties['color']
        gray60: React.CSSProperties['color']
        gray40: React.CSSProperties['color']
        MainGray: React.CSSProperties['color']
        MainLightgray: React.CSSProperties['color']
        OutlineGray: React.CSSProperties['color']
        purpleGradient: React.CSSProperties['color']
      }
    }
    color: {
      light: React.CSSProperties['color']
      dark: React.CSSProperties['color']
      error: React.CSSProperties['color']
      white: React.CSSProperties['color']
      black: React.CSSProperties['color']
      lightBlack: React.CSSProperties['color']
      gray: React.CSSProperties['color']
      carbon: React.CSSProperties['color']
      lemon: React.CSSProperties['color']
      purple: React.CSSProperties['color']
      lightPurple: React.CSSProperties['color']
      darkPurple: React.CSSProperties['color']
      darkPurple10: React.CSSProperties['color']
      lightGray: React.CSSProperties['color']
      darkGray: React.CSSProperties['color']
      darkBlue: React.CSSProperties['color']
      linen: React.CSSProperties['color']
      creme: React.CSSProperties['color']
      lightBlue: React.CSSProperties['color']
      lightLemon: React.CSSProperties['color']
      lavender: React.CSSProperties['color']
      darkWhite: React.CSSProperties['color']
      mediumGray: React.CSSProperties['color']
      royalBlue: React.CSSProperties['color']
      royalBlueTint: React.CSSProperties['color']
      lightLavender: React.CSSProperties['color']
      extraLightGray: React.CSSProperties['color']
      nobel: React.CSSProperties['color']
      dustyGray: React.CSSProperties['color']
      gray64: React.CSSProperties['color']
      darkerBlue: React.CSSProperties['color']
      cultured: React.CSSProperties['color']
      cultured10: React.CSSProperties['color']
      perano: React.CSSProperties['color']
      sanMarino: React.CSSProperties['color']
      paleCerulean: React.CSSProperties['color']
      cornflower: React.CSSProperties['color']
      volt: React.CSSProperties['color']
      gray0: React.CSSProperties['color']
      gray05: React.CSSProperties['color']
      gray10: React.CSSProperties['color']
      gray40: React.CSSProperties['color']
      gray60: React.CSSProperties['color']
      gray80: React.CSSProperties['color']
      gray100: React.CSSProperties['color']
      gray400: React.CSSProperties['color']
      antiFlashWhite: React.CSSProperties['color']
      paleViolet: React.CSSProperties['color']
      violet: React.CSSProperties['color']
      aeroBlue: React.CSSProperties['color']
      cornflowerBlue: React.CSSProperties['color']
      philippineGray: React.CSSProperties['color']
      primary10: React.CSSProperties['color']
      mask: React.CSSProperties['color']
      accent20: React.CSSProperties['color']
      MainGray: React.CSSProperties['color']
      MainLightgray: React.CSSProperties['color']
      OutlineGray: React.CSSProperties['color']
      purpleGradient: React.CSSProperties['color']
      greyerWarm: React.CSSProperties['color']
      emerald: React.CSSProperties['color']
      platinum: React.CSSProperties['color']
    }
    letterSpacing: {
      normal: React.CSSProperties['letterSpacing']
    }
    lineHeight: {
      normal: React.CSSProperties['lineHeight']
    }
  }
  interface ThemeOptions {
    navigation?: {
      height: number
    }
    chipStatus?: {
      first: {
        backgroundColor: React.CSSProperties['backgroundColor']
        color: React.CSSProperties['color']
      }
      second: {
        backgroundColor: React.CSSProperties['backgroundColor']
        color: React.CSSProperties['color']
      }
      third: {
        backgroundColor: React.CSSProperties['backgroundColor']
        color: React.CSSProperties['color']
      }
      fourth: {
        backgroundColor: React.CSSProperties['backgroundColor']
        color: React.CSSProperties['color']
      }
      fifth: {
        backgroundColor: React.CSSProperties['backgroundColor']
        color: React.CSSProperties['color']
      }
      sixth: {
        backgroundColor: React.CSSProperties['backgroundColor']
        color: React.CSSProperties['color']
      }
    }
    border?: {
      color: {
        light: React.CSSProperties['borderColor']
        dark: React.CSSProperties['borderColor']
        error: React.CSSProperties['borderColor']
        white: React.CSSProperties['borderColor']
        gray: React.CSSProperties['borderColor']
        carbon: React.CSSProperties['borderColor']
        lemon: React.CSSProperties['borderColor']
        lightCreme: React.CSSProperties['borderColor']
        lightGray: React.CSSProperties['borderColor']
      }
      radius: {
        small: React.CSSProperties['borderRadius']
        medium: React.CSSProperties['borderRadius']
        large: React.CSSProperties['borderRadius']
        xxl: React.CSSProperties['borderRadius']
        xxxl: React.CSSProperties['borderRadius']
        xxxxl: React.CSSProperties['borderRadius']
        xxxxxl: React.CSSProperties['borderRadius']
        circle: React.CSSProperties['borderRadius']
        mega: React.CSSProperties['borderRadius']
      }
      width: {
        small: React.CSSProperties['borderWidth']
        medium: React.CSSProperties['borderWidth']
        large: React.CSSProperties['borderWidth']
        xlarge: React.CSSProperties['borderWidth']
      }
    }
    font?: {
      weight: {
        light: React.CSSProperties['fontWeight']
        regular: React.CSSProperties['fontWeight']
        medium: React.CSSProperties['fontWeight']
        bold: React.CSSProperties['fontWeight']
        extraBold: React.CSSProperties['fontWeight']
      }
      size: {
        supertiny: React.CSSProperties['fontSize']
        tiny: React.CSSProperties['fontSize']
        small: React.CSSProperties['fontSize']
        compact: React.CSSProperties['fontSize']
        medium: React.CSSProperties['fontSize']
        mediumLarge: React.CSSProperties['fontSize']
        large: React.CSSProperties['fontSize']
        regular: React.CSSProperties['fontSize']
        xlarge: React.CSSProperties['fontSize']
        xxlarge: React.CSSProperties['fontSize']
        xxxlarge: React.CSSProperties['fontSize']
        billboard: React.CSSProperties['fontSize']
        jumbo: React.CSSProperties['fontSize']
        mega: React.CSSProperties['fontSize']
      }
      family: {
        almarenaDisplay: React.CSSProperties['fontFamily']
        code: React.CSSProperties['fontFamily']
        novemberCompressedHeavy: React.CSSProperties['fontFamily']
        barlow: React.CSSProperties['fontFamily']
        barlowCondensed: React.CSSProperties['fontFamily']
        satoshi: React.CSSProperties['fontFamily']
      }
      color: {
        indigo: React.CSSProperties['color']
        green: React.CSSProperties['color']
        red: React.CSSProperties['color']
        gray: React.CSSProperties['color']
        blue: React.CSSProperties['color']
        black: React.CSSProperties['color']
        lemon: React.CSSProperties['color']
        darkerBlue: React.CSSProperties['color']
        graniteGray: React.CSSProperties['color']
        primaryDarkContrast: React.CSSProperties['color']
        secondaryDarkContrast: React.CSSProperties['color']
        disabledDarkContrast: React.CSSProperties['color']
        primaryLightContrast: React.CSSProperties['color']
        secondaryLightContrast: React.CSSProperties['color']
        disabledLightContrast: React.CSSProperties['color']
        white: React.CSSProperties['color']
        darkGray: React.CSSProperties['color']
        carbon: React.CSSProperties['color']
        lightGray: React.CSSProperties['color']
        gray64: React.CSSProperties['color']
        gray400: React.CSSProperties['color']
        gray100: React.CSSProperties['color']
        sanMarino: React.CSSProperties['color']
        gray60: React.CSSProperties['color']
        gray40: React.CSSProperties['color']
        MainGray: React.CSSProperties['color']
        MainLightgray: React.CSSProperties['color']
        OutlineGray: React.CSSProperties['color']
        purpleGradient: React.CSSProperties['color']
      }
    }
    color?: {
      light: React.CSSProperties['color']
      dark: React.CSSProperties['color']
      error: React.CSSProperties['color']
      white: React.CSSProperties['color']
      black: React.CSSProperties['color']
      lightBlack: React.CSSProperties['color']
      gray: React.CSSProperties['color']
      carbon: React.CSSProperties['color']
      lemon: React.CSSProperties['color']
      purple: React.CSSProperties['color']
      lightPurple: React.CSSProperties['color']
      darkPurple: React.CSSProperties['color']
      darkPurple10: React.CSSProperties['color']
      lightGray: React.CSSProperties['color']
      darkGray: React.CSSProperties['color']
      darkBlue: React.CSSProperties['color']
      creme: React.CSSProperties['color']
      linen: React.CSSProperties['color']
      lightBlue: React.CSSProperties['color']
      lightLemon: React.CSSProperties['color']
      lavender: React.CSSProperties['color']
      darkWhite: React.CSSProperties['color']
      mediumGray: React.CSSProperties['color']
      royalBlue: React.CSSProperties['color']
      royalBlueTint: React.CSSProperties['color']
      lightLavender: React.CSSProperties['color']
      extraLightGray: React.CSSProperties['color']
      nobel: React.CSSProperties['color']
      dustyGray: React.CSSProperties['color']
      gray64: React.CSSProperties['color']
      darkerBlue: React.CSSProperties['color']
      cultured: React.CSSProperties['color']
      cultured10: React.CSSProperties['color']
      perano: React.CSSProperties['color']
      sanMarino: React.CSSProperties['color']
      paleCerulean: React.CSSProperties['color']
      cornflower: React.CSSProperties['color']
      volt: React.CSSProperties['color']
      gray0: React.CSSProperties['color']
      gray05: React.CSSProperties['color']
      gray10: React.CSSProperties['color']
      gray40: React.CSSProperties['color']
      gray60: React.CSSProperties['color']
      gray80: React.CSSProperties['color']
      gray100: React.CSSProperties['color']
      gray400: React.CSSProperties['color']
      antiFlashWhite: React.CSSProperties['color']
      paleViolet: React.CSSProperties['color']
      violet: React.CSSProperties['color']
      aeroBlue: React.CSSProperties['color']
      cornflowerBlue: React.CSSProperties['color']
      philippineGray: React.CSSProperties['color']
      primary10: React.CSSProperties['color']
      mask: React.CSSProperties['color']
      accent20: React.CSSProperties['color']
      MainGray: React.CSSProperties['color']
      MainLightgray: React.CSSProperties['color']
      OutlineGray: React.CSSProperties['color']
      purpleGradient: React.CSSProperties['color']
      greyerWarm: React.CSSProperties['color']
      emerald: React.CSSProperties['color']
      platinum: React.CSSProperties['color']
    }
    letterSpacing?: {
      normal: React.CSSProperties['letterSpacing']
    }
    lineHeight?: {
      normal: React.CSSProperties['lineHeight']
    }
  }
}

const customTheme: ThemeOptions = {
  props: {
    MuiLink: {
      color: 'textPrimary',
    },
    MuiButtonBase: {
      disableRipple: true,
      disableTouchRipple: true,
    },
    // https://material-ui.com/components/typography/#component
    /* MuiTypography: {
      variantMapping: {
        h1: 'h2',
        h2: 'h2',
        h3: 'h2',
        h4: 'h2',
        h5: 'h2',
        h6: 'h2',
        subtitle1: 'h2',
        subtitle2: 'h2',
        body1: 'span',
        body2: 'span',
      },
    }, */
  },
  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      main: '#4138C2',
      // dark: will be calculated from palette.primary.main,
      contrastText: '#fff',
      // If the "dark" and / or "light" keys are omitted, their value(s) will be calculated from "main", according to the "tonalOffset" value.
    },
    secondary: {
      light: '#96a7aa',
      main: '#6b7280',
      dark: '#4B5563',
      contrastText: '#fff',
      // If "contrastText" is omitted, its value will be calculated to contrast with "main", according to the "contrastThreshold" value.
    },
    // Used by `getContrastText()` to maximize the contrast between
    // the background and the text.
    contrastThreshold: 3,
    // Used by the functions below to shift a color's luminance by approximately
    // two indexes within its tonal palette.
    // E.g., shift from Red 500 to Red 300 or Red 700.
    tonalOffset: 0.2,
    // Both the "tonalOffset" and "contrastThreshold" values may be customized as needed. The "tonalOffset" value can either be a number between 0 and 1,
    // which will apply to both light and dark variants, or an object with light and dark variants specified by the following TypeScript type:
    // type PaletteTonalOffset = number | {
    // light: number;
    // dark: number;
    // };
    text: {
      disabled: 'rgba(0, 0, 0, 0.38)',
      hint: 'rgba(0, 0, 0, 0.38)',
      primary: 'rgba(0, 0, 0, 0.87)',
      secondary: 'rgba(0, 0, 0, 0.54)',
    },
    background: { paper: '#fff', default: '#fff' },
  },
  // https://material-ui.com/customization/typography/
  typography: {
    h1: {
      fontFamily: '"Barlow", sans-serif',
      fontWeight: 600,
      fontSize: '22px',
    },
    h2: {
      fontFamily: '"Barlow", sans-serif',
      fontWeight: 300,
      fontSize: '2.375rem',
      lineHeight: 1.2,
      letterSpacing: '-0.00833em',
    },
    h3: {
      fontFamily: 'AlmarenaDisplay',
      fontWeight: 400,
      fontSize: '2rem',
      lineHeight: 1.167,
      letterSpacing: '0em',
    },
    h4: {
      fontFamily: '"Barlow", sans-serif',
      fontWeight: 400,
      fontSize: '1.5625rem',
      lineHeight: 1.235,
      letterSpacing: '0.00735em',
    },
    h5: {
      fontFamily: '"Barlow", sans-serif',
      fontWeight: 400,
      fontSize: '1.25rem',
      lineHeight: 1.334,
      letterSpacing: '0em',
    },
    h6: {
      fontFamily: '"Barlow", sans-serif',
      fontWeight: 500,
      fontSize: '1.125rem',
      lineHeight: 1.6,
      letterSpacing: '0.0075em',
    },
    subtitle1: {
      fontFamily: '"Barlow", sans-serif',
      fontWeight: 400,
      fontSize: '1rem',
      lineHeight: 1.75,
      letterSpacing: '0.00938em',
    },
    subtitle2: {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: 500,
      fontSize: '0.875rem',
      lineHeight: 1.57,
      letterSpacing: '0.00714em',
    },
    body1: {
      fontFamily: '"Barlow", sans-serif',
      fontWeight: 200,
      fontSize: '16px',
    },
    body2: {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: 400,
      fontSize: '0.875rem',
      lineHeight: 1.43,
      letterSpacing: '0.01071em',
    },
    button: {
      fontFamily: '"Barlow", sans-serif',
      fontWeight: 500,
      fontSize: '0.875rem',
      lineHeight: 1.75,
      letterSpacing: '0.02857em',
      textTransform: 'uppercase',
    },
    caption: {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: 400,
      fontSize: '0.75rem',
      lineHeight: 1.66,
      letterSpacing: '0.03333em',
    },
    overline: {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: 400,
      fontSize: '0.75rem',
      lineHeight: 2.66,
      letterSpacing: '0.08333em',
      textTransform: 'uppercase',
    },
  },
}

const createMyTheme = (options: ThemeOptions) => {
  return createTheme({
    chipStatus: {
      first: { backgroundColor: '#ede9fe', color: '#5b21b6' },
      second: { backgroundColor: '#dbeafe', color: '#1e40af' },
      third: { backgroundColor: '#d1fae5', color: '#065f46' },
      fourth: { backgroundColor: '#e5e7eb', color: '#000' },
      fifth: { backgroundColor: '#fef3c7', color: '#92400e' },
      sixth: { backgroundColor: '#ffcaca', color: '#92400e' },
    },
    border: {
      color: {
        light: '#C7D2FE',
        dark: '#4138C2',
        error: '#FF0000',
        white: '#FFFFFF',
        gray: '#E5E7EB',
        carbon: '#1E1E1E',
        lemon: '#DDFF00',
        lightCreme: '#FFFBF2',
        lightGray: '#DFE4EA',
      },
      radius: {
        small: '3px',
        medium: '5px',
        large: '8px',
        xxl: '15px',
        xxxl: '20px',
        xxxxl: '30px',
        xxxxxl: '55px',
        circle: '50%',
        mega: '200px',
      },
      width: {
        small: '1px',
        medium: '5px',
        large: '6px',
        xlarge: '10px',
      },
    },
    font: {
      weight: {
        light: 200,
        regular: 400,
        medium: 500,
        bold: 800,
        extraBold: 900,
      },
      size: {
        supertiny: '8px',
        tiny: '10px',
        small: '12px',
        compact: '14px',
        medium: '16px',
        mediumLarge: '18px',
        large: '20px',
        regular: '25px',
        xlarge: '32px',
        xxlarge: '40px',
        xxxlarge: '50px',
        billboard: '52px',
        jumbo: '65px',
        mega: '82px',
      },
      family: {
        almarenaDisplay: "'AlmarenaDisplay', sans-serif",
        code: 'Menlo, Consolas, monospace',
        novemberCompressedHeavy: 'November Compressed Heavy',
        barlow: 'Barlow',
        barlowCondensed: 'Barlow Condensed',
        satoshi: 'Satoshi-Variable',
      },
      color: {
        indigo: '#4338CA',
        green: '#10B981',
        red: '#FF0000',
        gray: '#6B7280',
        blue: '#4E55F9',
        black: '#000000',
        lemon: '#DDFF00',
        darkerBlue: '#7BAED2',
        graniteGray: '#5E5E5E',
        primaryDarkContrast: '#202532',
        secondaryDarkContrast: '#50545E',
        disabledDarkContrast: '#838791',
        primaryLightContrast: '#FFFFFF',
        secondaryLightContrast: '#ffffffb8',
        disabledLightContrast: '#ffffff66',
        white: '#FFFFFF',
        darkGray: '#333333',
        carbon: '#1E1E1E',
        lightGray: '#999999',
        gray64: '#A4A4A4',
        gray400: '#7B8794',
        sanMarino: '#3F7297',
        gray100: '#202532',
        gray60: '#838791',
        gray40: '#BEC0C5',
        MainGray: '#222222',
        MainLightgray: '#8F8C9B',
        OutlineGray: '#E76E9',
        purpleGradient: '#7B3DFF',
      },
    },
    color: {
      light: '#C7D2FE',
      dark: '#4138C2',
      error: '#FF0000',
      white: '#FFFFFF',
      black: '#000000',
      lightBlack: '#4A4A4A',
      gray: '#d1d5db',
      carbon: '#1E1E1E',
      lemon: '#DDFF00',
      lightLemon: '#afbb5d',
      purple: '#4138C2',
      lightPurple: '#7a54f9',
      darkPurple: '#5D3DFF',
      darkPurple10: '#2D2AA1',
      lightGray: '#999999',
      darkGray: '#333333',
      darkBlue: '#0a39ff',
      lightBlue: '#0a39ff70',
      linen: '#fbf3eb',
      creme: '#FDF7EE',
      lavender: '#9C80FF',
      darkWhite: '#EBEBEB',
      mediumGray: '#868686',
      royalBlue: '#D7CFFF',
      royalBlueTint: '#886EFF',
      lightLavender: '#D9CEFF',
      extraLightGray: '#F1F1F1',
      nobel: '#b4b4b4',
      dustyGray: '#979797',
      gray64: '#A4A4A4',
      darkerBlue: '#7BAED2',
      cultured: '#F4F4F4',
      cultured10: '#F3F3FA',
      perano: '#AED2EB',
      sanMarino: '#3F7297',
      paleCerulean: '#A4C8E2',
      cornflower: '#8BBEE2',
      volt: '#D6F400',
      gray0: '#F2F2F3',
      gray05: '#EAEAEB',
      gray10: '#D9DADD',
      gray40: '#BEC0C5',
      gray60: '#838791',
      gray80: '#50545E',
      gray100: '#202532',
      gray400: '#7B8794',
      antiFlashWhite: '#E8F2F9',
      paleViolet: '#BBA8FF',
      aeroBlue: '#D6F3E2',
      cornflowerBlue: '#48B3FF',
      philippineGray: '#8B8B8B',
      primary10: '#D1E5F3',
      violet: '#6100ff',
      mask: '#1e1d1dd1',
      accent20: '#DFFF00',
      MainGray: '#222222',
      MainLightgray: '#8F8C9B',
      OutlineGray: '#E76E9',
      purpleGradient: '#7B3DFF',
      greyerWarm: '#3E3A32',
      emerald: '#8FFFC3',
      platinum: '#E2E2E2',
    },
    letterSpacing: {
      normal: '0.773617px',
    },
    lineHeight: {
      normal: '1.3',
    },
    navigation: {
      height: 64,
    },
    ...options,
  })
}

// https://material-ui.com/customization/theming/#createmuitheme-options-args-theme
let theme = createMyTheme(customTheme)
// https://material-ui.com/customization/theming/#responsivefontsizes-theme-options-theme
theme = responsiveFontSizes(theme)

export default theme
