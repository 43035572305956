import theme from '../theme'
/**
 * All possible font variants
 * BODY_LARGE by default is using Regular font weight (400)
 * BODY_MEDIUM by default is using Regular font weight (400)
 * BODY_SMALL by default is using Reguar font weight (400)
 */
export enum Variant {
  H900 = 'H900',
  H800 = 'H800',
  H700 = 'H700',
  H600 = 'H600',
  H500 = 'H500',
  H400 = 'H400',
  H300 = 'H300',
  H200 = 'H200',
  H100 = 'H100',

  MOBILE_H800 = 'MOBILE_H800',
  MOBILE_H600 = 'MOBILE_H600',
  MOBILE_H400 = 'MOBILE_H400',

  DESKTOP_TITLE = 'DESKTOP_TITLE',
  MOBILE_TITLE = 'MOBILE_TITLE',
  SUBTITLE = 'SUBTITLE',
  BODY_LARGE = 'BODY_LARGE',
  BODY_MEDIUM = 'BODY_MEDIUM',
  BODY_SMALL = 'BODY_SMALL',
  BUTTON_DESKTOP = 'BUTTON_DESKTOP',
  BUTTON_MOBILE = 'BUTTON_MOBILE',

  APPLICATION_TITLE_OVERLAY_LARGE = 'APPLICATION_TITLE_OVERLAY_LARGE',
  APPLICATION_TITLE_OVERLAY_MEDIUM = 'APPLICATION_TITLE_OVERLAY_MEDIUM',
  APPLICATION_TITLE_OVERLAY_SMALL = 'APPLICATION_TITLE_OVERLAY_SMALL',
  APPLICATION_TITLE_OVERLAY_XSMALL = 'APPLICATION_TITLE_OVERLAY_XSMALL',

  NOTE = 'NOTE',
  NOTE2 = 'NOTE2',

  LANDING_32 = 'LANDING_32',
}

export const fontWeight = Object.freeze({
  regular: 400,
  medium: 500,
  semibold: 600,
  bold: 700,
})

export type FontWeight = keyof typeof fontWeight

export type TypographyConfiguration = {
  fontFamily: React.CSSProperties['fontFamily']
  fontWeight: number
  fontSize: number
  lineHeight: string
  letterSpacing?: string
}

export const styles: { [key in keyof typeof Variant]: TypographyConfiguration } = {
  [Variant.H900]: {
    fontFamily: theme.font.family.satoshi,
    fontWeight: 900,
    fontSize: 100,
    lineHeight: '108px',
    letterSpacing: '2px',
  },
  [Variant.H800]: {
    fontFamily: theme.font.family.satoshi,
    fontWeight: 900,
    fontSize: 60,
    lineHeight: '63px',
    letterSpacing: '1px',
  },
  [Variant.H700]: {
    fontFamily: theme.font.family.satoshi,
    fontWeight: 900,
    fontSize: 50,
    lineHeight: '50px',
    letterSpacing: '1px',
  },
  [Variant.H600]: {
    fontFamily: theme.font.family.satoshi,
    fontWeight: 600,
    fontSize: 50,
    lineHeight: '60px',
  },
  [Variant.H500]: {
    fontFamily: theme.font.family.satoshi,
    fontWeight: 500,
    fontSize: 26,
    lineHeight: '30px',
  },
  [Variant.H400]: {
    fontFamily: theme.font.family.satoshi,
    fontWeight: 500,
    fontSize: 22,
    lineHeight: '26px',
  },
  [Variant.H300]: {
    fontFamily: theme.font.family.satoshi,
    fontWeight: 600,
    fontSize: 16,
    lineHeight: '24px',
    letterSpacing: '1px',
  },
  [Variant.H200]: {
    fontFamily: theme.font.family.satoshi,
    fontWeight: 600,
    fontSize: 14,
    lineHeight: '17px',
  },
  [Variant.H100]: {
    fontFamily: theme.font.family.satoshi,
    fontWeight: 600,
    fontSize: 12,
    lineHeight: '16px',
  },
  [Variant.SUBTITLE]: {
    fontFamily: theme.font.family.satoshi,
    fontWeight: 600,
    fontSize: 22,
    lineHeight: '32px',
  },
  [Variant.MOBILE_H800]: {
    fontFamily: theme.font.family.satoshi,
    fontWeight: fontWeight.bold,
    fontSize: 35,
    lineHeight: '38px',
    letterSpacing: '1px',
  },
  [Variant.MOBILE_H400]: {
    fontFamily: theme.font.family.satoshi,
    fontWeight: 600,
    fontSize: 18,
    lineHeight: '22.5px',
  },
  [Variant.BUTTON_DESKTOP]: {
    fontFamily: theme.font.family.satoshi,
    fontWeight: 900,
    fontSize: 28,
    lineHeight: '30px',
    letterSpacing: '1px',
  },
  [Variant.BUTTON_MOBILE]: {
    fontFamily: theme.font.family.satoshi,
    fontWeight: fontWeight.bold,
    fontSize: 20,
    lineHeight: '26px',
  },
  [Variant.BODY_LARGE]: {
    fontFamily: theme.font.family.satoshi,
    fontSize: 18,
    lineHeight: '24px',
    fontWeight: fontWeight.regular,
  },
  [Variant.BODY_MEDIUM]: {
    fontFamily: theme.font.family.satoshi,
    fontSize: 14,
    lineHeight: '20px',
    fontWeight: fontWeight.regular,
  },
  [Variant.BODY_SMALL]: {
    fontFamily: theme.font.family.satoshi,
    fontSize: 12,
    lineHeight: '16px',
    letterSpacing: '0.2px',
    fontWeight: fontWeight.regular,
  },
  [Variant.APPLICATION_TITLE_OVERLAY_LARGE]: {
    fontFamily: theme.font.family.satoshi,
    fontSize: 30,
    lineHeight: '30px',
    letterSpacing: '0.16px',
    fontWeight: 900,
  },
  [Variant.APPLICATION_TITLE_OVERLAY_MEDIUM]: {
    fontFamily: theme.font.family.satoshi,
    fontSize: 18,
    lineHeight: '18px',
    letterSpacing: '0.16px',
    fontWeight: 900,
  },
  [Variant.APPLICATION_TITLE_OVERLAY_SMALL]: {
    fontFamily: theme.font.family.satoshi,
    fontSize: 16,
    lineHeight: '16px',
    letterSpacing: '0.16px',
    fontWeight: 900,
  },
  [Variant.APPLICATION_TITLE_OVERLAY_XSMALL]: {
    fontFamily: theme.font.family.satoshi,
    fontSize: 11,
    lineHeight: '14px',
    letterSpacing: '0.16px',
    fontWeight: 900,
  },
  [Variant.NOTE]: {
    fontFamily: theme.font.family.satoshi,
    fontSize: 8,
    lineHeight: '11px',
    fontWeight: 400,
  },
  [Variant.NOTE2]: {
    fontFamily: theme.font.family.satoshi,
    fontSize: 10,
    lineHeight: '12px',
    fontWeight: 400,
  },
  [Variant.DESKTOP_TITLE]: {
    fontFamily: theme.font.family.satoshi,
    fontSize: 36,
    lineHeight: '24px',
    letterSpacing: '0px',
    fontWeight: 400,
  },
  [Variant.MOBILE_TITLE]: {
    fontFamily: theme.font.family.satoshi,
    fontSize: 24,
    lineHeight: '24px',
    letterSpacing: '0px',
    fontWeight: 400,
  },
  [Variant.MOBILE_H600]: {
    fontFamily: theme.font.family.satoshi,
    fontWeight: 600,
    fontSize: 40,
    lineHeight: '40px',
  },
  [Variant.LANDING_32]: {
    fontFamily: theme.font.family.satoshi,
    fontWeight: 600,
    fontSize: 32,
    lineHeight: '36.8px',
  },
}
