import styled from 'styled-components'
import { TextField as MuiTextField } from '@material-ui/core'
import ReactGoogleButton from 'react-google-button'
import Button from '@components/Button/Button'

export const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  padding: 50px 120px;
  align-items: center;
  text-align: center;
  min-height: 315px;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    padding: 40px 25px;
  }
`

export const TextField = styled(MuiTextField)`
  width: 100%;
  margin-top: 15px;

  .MuiOutlinedInput-root {
    border-radius: ${({ theme }) => theme.border.radius.xxxxxl};
    height: 50px;
  }

  fieldset {
    border-color: ${({ theme }) => theme.color.lightGray};
  }

  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: ${({ theme }) => theme.color.lightGray};
    border-width: 1px;
  }

  input {
    text-align: center;
  }

  ${({ theme }) => theme.breakpoints.down('xs')} {
    width: 100%;
    input {
      font-size: ${({ theme }) => theme.font.size.compact};
    }
  }
`

export const ErrorMessage = styled.div`
  color: ${({ theme }) => theme.color.error};
`

export const NextButton = styled(Button)`
  padding: 10px 25px;
  width: 100%;

  .MuiButton-endIcon {
    margin-left: 10px;
    font-size: ${({ theme }) => theme.font.size.large};
  }
`

export const GoogleButton = styled(ReactGoogleButton)`
  border: 1px solid ${({ theme }) => theme.color.gray40} !important;
  box-shadow: rgb(0 0 0 / 25%) 0px 2px 4px 0px !important;
  width: 100% !important;
  border-radius: 55px !important;

  div,
  svg {
    width: 50px !important;
    height: 50px !important;
    position: relative;
    left: 23px;
    top: -1px;
    background-color: transparent !important;
  }
  span {
    font-family: ${({ theme }) => theme.font.family.barlowCondensed};
    font-weight: ${({ theme }) => theme.font.weight.medium};
    font-size: ${({ theme }) => theme.font.size.medium};
    line-height: ${({ theme }) => theme.font.size.regular};
    letter-spacing: 1px;
    position: relative;
    left: -13px;
  }
  ${({ theme }) => theme.breakpoints.down('xs')} {
    div,
    svg {
      left: 10px;
    }
  }
`

export const Separator = styled.div`
  margin: 0 5px 0 5px;
  background: ${({ theme }) => theme.color.gray40};
  height: 1px;
  width: 100%;
`

export const ToggleButtonGroupContainer = styled.div`
  margin: 16px 0;

  .MuiToggleButton-root.Mui-selected {
    background: ${({ theme }) => theme.color.violet};
    color: ${({ theme }) => theme.color.white};
  }
`
