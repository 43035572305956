import { useEffect, useState } from 'react'
import {
  useInitAuthMutation,
  useVerifyOtpMutation,
  useRefreshTokenMutation,
} from '@generated/graphql'
import Login from '@components/Login/Login'
import { startTokenRefreshTimer, cognitologout } from '../utils/cognito'
import CognitoContext, { GenerateEmailAuthCodeResponse } from './CognitoContext'
import AuthStorageManager from '../AuthStorageManager'

export type Props = {
  children: React.ReactNode
}

const CognitoProvider: React.FC = ({ children }: Props) => {
  const [showLogin, setShowLogin] = useState<boolean>(false)
  const [redirectUrl, setRedirectUrl] = useState<string | undefined>(undefined)
  const [footerMessage, setFooterMessage] = useState<string>('')
  const [disableCloseButton, setDisableCloseButton] = useState<boolean>(false)
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [initAuthMutation] = useInitAuthMutation()
  const [verifyOtpMutation] = useVerifyOtpMutation()
  const [refreshTokenMutation] = useRefreshTokenMutation()

  const generateEmailAuthCode = async (email: string): Promise<GenerateEmailAuthCodeResponse> => {
    setIsLoading(true)
    try {
      const response = await initAuthMutation({
        variables: {
          input: {
            username: email,
          },
        },
      })
      if (response.data?.initAuth.success) {
        setIsLoading(false)
        return {
          success: true,
          email,
          errorMessage: undefined,
        }
      } else {
        return {
          success: false,
          email,
          errorMessage: response.data?.initAuth.error?.message!,
        }
      }
    } catch (error: unknown) {
      setIsLoading(false)
      return {
        success: false,
        email,
        errorMessage: (error as { message: string }).message,
      }
    }
  }

  const cognitologinWithEmailAuthCode = async (
    email: string,
    verificationCode: string,
    redirectUrl?: string
  ): Promise<GenerateEmailAuthCodeResponse> => {
    setIsLoading(true)
    setShowLogin(true)
    try {
      const response = await verifyOtpMutation({
        variables: {
          input: {
            username: email,
            otp: verificationCode,
          },
        },
      })
      setIsLoading(false)
      if (response.data?.verifyOtp.__typename === 'VerifyAuthResponse') {
        setShowLogin(false)
        AuthStorageManager.set(JSON.stringify(response.data.verifyOtp))
        setIsAuthenticated(true)
        startTokenRefreshTimer(response.data.verifyOtp.expiresIn)
        return {
          success: true,
          email,
          errorMessage: undefined,
        }
      } else {
        return {
          success: false,
          email,
          errorMessage: response.data?.verifyOtp.message!,
        }
      }
    } catch (error: unknown) {
      setIsLoading(false)
      return {
        success: false,
        email,
        errorMessage: (error as { message: string }).message,
      }
    }
  }

  // This component mounts once during the applications lifecycle.
  // When this component mounts, we'll check storage for an auth token.
  // If one exists, we'll attempt to refresh the session.  If the token
  // is valid, the requested page will load normally. If not, we'll redirect
  // to the login page.
  useEffect(() => {
    const refreshTokenOnMount = async () => {
      const store = JSON.parse(AuthStorageManager.get()?.toString() || '{}')
      if (store.refreshToken) {
        setIsLoading(true)
        try {
          const response = await refreshTokenMutation({
            variables: {
              refreshToken: store.refreshToken,
            },
          })
          setIsLoading(false)
          if (response.data?.refreshToken.__typename === 'RefreshTokenResponse') {
            setIsAuthenticated(true)
            setIsLoading(false)
            setShowLogin(false)
            AuthStorageManager.set(
              JSON.stringify({ ...response.data.refreshToken, refreshToken: store.refreshToken })
            )
            startTokenRefreshTimer(store.expiresIn)
          } else {
            // The user's token was expired, so we can't refresh it.
            // They will need to log in again.
            setIsAuthenticated(false)
            setIsLoading(false)
            setShowLogin(true)
          }
        } catch (error: unknown) {
          setIsLoading(false)
          setIsAuthenticated(false)
          setShowLogin(true)
        }
      } else {
        setIsLoading(false)
      }
    }
    refreshTokenOnMount()
  }, [])

  return (
    <CognitoContext.Provider
      value={{
        generateEmailAuthCode,
        loginWithEmailAuthCode: cognitologinWithEmailAuthCode,
        isAuthenticated,
        logout: cognitologout,
        isLoading,
        login: (args?: {
          disableCloseButton?: boolean
          footerMessage?: string
          redirectUrl?: string
          embeddedLogin?: boolean
        }) => {
          setDisableCloseButton(!!args?.disableCloseButton)
          setFooterMessage(args?.footerMessage || '')
          setRedirectUrl(args?.redirectUrl)
          setShowLogin(true)
        },
        closeModal: () => {
          if (showLogin) {
            setShowLogin(false)
          }
        },
        setAuthInfo: async () => {
          const store = JSON.parse(AuthStorageManager.get()?.toString() || '{}')
          if (store.accessToken) {
            setIsLoading(true)
            setIsLoading(false)
            setIsAuthenticated(true)
            setShowLogin(false)
          }
        },
      }}
    >
      {children}
      <Login
        showModal={showLogin}
        setShowModal={setShowLogin}
        footerMessage={footerMessage}
        disableCloseButton={disableCloseButton}
        redirectUrl={redirectUrl}
      />
    </CognitoContext.Provider>
  )
}

export default CognitoProvider
