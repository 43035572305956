import * as S from './LoadingSpinner.styles'

export type Props = {
  fullScreen?: boolean
  size: keyof typeof S.SpinnerSize
}

const LoadingSpinner: React.FC<Props> = ({ fullScreen, size }) => {
  return (
    <S.Screen full={fullScreen}>
      <S.SpinnerWrapper size={size}>
        <S.LoadingCircle size={size} />
        <S.LoadingLineMask size={size}>
          <S.LoaderLine size={size} />
        </S.LoadingLineMask>
        <S.LoadingLineMask size={size}>
          <S.LoaderLine size={size} />
        </S.LoadingLineMask>
      </S.SpinnerWrapper>
    </S.Screen>
  )
}

export default LoadingSpinner
