import * as S from './RaiseFooter.styles'
import { Box } from '@material-ui/core'
import Copyright from './components/Copyright/Copyright'
import React from 'react'
import Typography from '@components/Typography/Typography'
import NextLink from 'next/link'
import theme from '@components/theme'

const items: { name: string; link: string; external?: boolean }[] = [
  // {
  //   name: 'Discover',
  //   link: '/discover',
  //   external: false,
  // },
  {
    name: 'Mission',
    link: '/blog/mission',
    external: false,
  },
  // {
  //   name: 'House',
  //   link: '/house',
  //   external: false,
  // },
  {
    name: 'FAQ',
    link: 'https://protege.notion.site/FAQs-6f5c6b341dc1484589cf0afdbf9d290e',
    external: true,
  },
  {
    name: 'Careers',
    link: 'https://protege.notion.site/Careers-at-Protege-08a4ebf7672b4e51a1ba4cf1af2e082d',
    external: true,
  },
  {
    name: 'Privacy',
    link: 'https://www.notion.so/protege/Privacy-Policy-471b0637e5ea4a9b987d2ce2051325f8',
    external: true,
  },
  {
    name: 'Terms',
    link: 'https://protege.notion.site/Terms-of-Service-dfb4e88eda9d4ac599ed0e1a49ee382e',
    external: true,
  },
]

// Icons are displayed in this order
const socialNetworkUrls = [
  'https://www.facebook.com/joinprotege',
  'https://www.instagram.com/joinprotege',
  'https://twitter.com/protege',
]

const RaiseFooter: React.FC = () => (
  <S.Footer>
    <S.Container>
      <S.List role="navigation" aria-label="Main">
        {items.map(({ name, link, external }) => {
          return (
            <S.Item key={name}>
              <NextLink href={link} passHref>
                <S.Link target={external ? '_blank' : undefined} rel="noreferrer">
                  <Typography color="primaryDarkContrast" variant="H400">
                    {name}
                  </Typography>
                </S.Link>
              </NextLink>
            </S.Item>
          )
        })}
      </S.List>
      <S.SocialIconContainer>
        {socialNetworkUrls.map((url) => (
          <S.SocialIcon
            key={url}
            bgColor={theme.font.color.primaryDarkContrast}
            url={url}
            target="_blank"
          />
        ))}
      </S.SocialIconContainer>
    </S.Container>
    <Box display="flex" flexDirection="row" alignItems="end" justifyContent="space-between">
      <S.CopyrightContainer>
        <Copyright />
      </S.CopyrightContainer>
    </Box>
  </S.Footer>
)

export default RaiseFooter
