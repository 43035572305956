import { useEffect, useState } from 'react'
import Cookies from 'js-cookie'
import Alert, { Props as AlertProps } from '../Alert/Alert'

export type Props = {
  cookieName: string
} & AlertProps

const COOKIE_VALUE = '1'

const SimpleAcknowledgementAlert: React.FC<Props> = ({
  cookieName: baseCookieName,
  onClose,
  ...alertProps
}) => {
  const cookieName = `protege.acknowledgement.${baseCookieName}`
  const [cookieValue, setCookieValue] = useState<typeof COOKIE_VALUE | null>(null)

  // Set the cookie when the `cookieValue` state variable value
  // changes.
  useEffect(() => {
    if (cookieValue && Cookies.get(cookieName) !== cookieValue) {
      Cookies.set(cookieName, cookieValue, {
        expires: 365 * 5,
      })
    }
  })

  // If the cookie is set, don't render the alert.
  if (cookieValue || Cookies.get(cookieName) === COOKIE_VALUE) {
    return null
  }

  return (
    <Alert
      {...alertProps}
      onClose={(event) => {
        setCookieValue(COOKIE_VALUE)
        onClose && onClose(event)
      }}
    />
  )
}

export default SimpleAcknowledgementAlert
