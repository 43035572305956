import { createContext, RefObject } from 'react'

export type VideoState = {
  ref: VideoRef
  muted: boolean
}

export type VideoRef = RefObject<HTMLVideoElement>
export type StateMap = Map<VideoRef, VideoState>

export interface SoundContextInterface {
  state: StateMap | null
  playingVideo: VideoState | null
  notifyPlayEvent: (videoRef: VideoRef) => void
  registerVideo: (videoRef: VideoRef, videoState?: VideoState) => void
  unRegisterVideo: (videoRef: VideoRef) => void
}

const stub = (): never => {
  throw new Error('You forgot to wrap your component in <SoundContext>.')
}
const initialContext: SoundContextInterface = {
  state: null,
  playingVideo: null,
  notifyPlayEvent: stub,
  registerVideo: stub,
  unRegisterVideo: stub,
}

const SoundContext = createContext<SoundContextInterface>(initialContext)

export default SoundContext
