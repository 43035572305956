import { ButtonProps } from '@material-ui/core/Button'
import * as S from './Button.style'
import { ButtonSize, ButtonVariant, buttonSizeVariant } from './Button.config'
import Typography from '../Typography/Typography'
import { memo } from 'react'

export type Props = {
  variant?: ButtonVariant
  size?: ButtonSize
} & Omit<ButtonProps, 'variant' | 'size'>

const Button: React.FC<Props> = ({ variant, size = 'medium', children, ...rest }) => {
  return (
    <S.Button buttonvariant={variant} buttonsize={size} disableRipple={true} {...rest}>
      <Typography variant={buttonSizeVariant[size]}>{children}</Typography>
    </S.Button>
  )
}

export default memo(Button)
