import styled from 'styled-components'
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert'

export const Alert = styled(MuiAlert)<AlertProps & { rounded: number }>`
  ${({ rounded }) =>
    !rounded &&
    `
  border-radius: 0;
`}

  .MuiAlert-message {
    width: 100%;
  }
`

export const TitleContainer = styled.div`
  margin-top: -2px;
`
