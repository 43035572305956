import { useEffect, useState } from 'react'
import {
  useAuthProviderGenerateAuthCodeMutation,
  useAuthProviderPostLoginUserLazyQuery,
  useAuthProviderCreateUserFromSocialMutation,
} from '@generated/graphql'
import Login from '@components/Login/Login'
import { setAuthInfo, refreshToken, loginWithEmailAuthCode, logout } from '../utils/auth0'
import Auth0Context, { Auth0Info, GenerateEmailAuthCodeResponse } from './Auth0Context'
import AuthStorageManager from '../AuthStorageManager'

export type Props = {
  children: React.ReactNode
}

const Auth0Provider: React.FC = ({ children }: Props) => {
  const [showLogin, setShowLogin] = useState<boolean>(false)
  const [redirectUrl, setRedirectUrl] = useState<string | undefined>(undefined)
  const [footerMessage, setFooterMessage] = useState<string>('')
  const [disableCloseButton, setDisableCloseButton] = useState<boolean>(false)
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [generateAuthCodeMutation] = useAuthProviderGenerateAuthCodeMutation()
  const [createUserFromSocialMutation] = useAuthProviderCreateUserFromSocialMutation()
  const [postLoginFetchUser] = useAuthProviderPostLoginUserLazyQuery()

  const generateEmailAuthCode = async (
    email: string,
    scholarshipApplicationId?: string
  ): Promise<GenerateEmailAuthCodeResponse> => {
    setIsLoading(true)
    try {
      await generateAuthCodeMutation({
        variables: {
          input: {
            email,
            referred: scholarshipApplicationId
              ? {
                  scholarshipApplicationId,
                }
              : null,
          },
        },
      })
      setIsLoading(false)
      return {
        success: true,
        email,
        errorMessage: undefined,
      }
    } catch (error: unknown) {
      setIsLoading(false)
      return {
        success: false,
        email,
        errorMessage: (error as { message: string }).message,
      }
    }
  }

  // This component mounts once during the applications lifecycle.
  // When this component mounts, we'll check storage for an auth token.
  // If one exists, we'll attempt to refresh the session.  If the token
  // is valid, the requested page will load normally. If not, we'll redirect
  // to the login page.
  useEffect(() => {
    const refreshTokenOnMount = async () => {
      if (AuthStorageManager.get()) {
        setIsLoading(true)

        const success = await refreshToken()
        if (success) {
          setIsAuthenticated(true)
          setIsLoading(false)
          setShowLogin(false)
        } else {
          // The user's token was expired, so we can't refresh it.
          // They will need to log in again.
          setIsAuthenticated(false)
          setIsLoading(false)
        }
      } else {
        setIsLoading(false)
      }
    }
    refreshTokenOnMount()
  }, [])

  return (
    <Auth0Context.Provider
      value={{
        generateEmailAuthCode,
        loginWithEmailAuthCode,
        setAuthInfo: async () => {
          await setAuthInfo(createUserFromSocialMutation)

          if ((AuthStorageManager.get() as Auth0Info)?.accessToken) {
            setIsLoading(true)
            await postLoginFetchUser()
            setIsLoading(false)
            setIsAuthenticated(true)
            setShowLogin(false)
          }
        },
        isAuthenticated,
        logout,
        isLoading,
        login: (args?: {
          disableCloseButton?: boolean
          footerMessage?: string
          redirectUrl?: string
          embeddedLogin?: boolean
        }) => {
          setDisableCloseButton(!!args?.disableCloseButton)
          setFooterMessage(args?.footerMessage || '')
          setRedirectUrl(args?.redirectUrl)
          setShowLogin(true)
        },
        closeModal: () => {
          if (showLogin) {
            setShowLogin(false)
          }
        },
      }}
    >
      {children}
      <Login
        showModal={showLogin}
        setShowModal={setShowLogin}
        footerMessage={footerMessage}
        disableCloseButton={disableCloseButton}
        redirectUrl={redirectUrl}
      />
    </Auth0Context.Provider>
  )
}

export default Auth0Provider
