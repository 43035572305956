import Typography from '@components/Typography/Typography'
import { makeStyles } from '@material-ui/core'
import { AlertProps } from '@material-ui/lab/Alert'

import * as S from './Alert.styles'

export type Props = {
  title?: string
  children?: React.ReactNode
  severity?: AlertProps['severity']
  variant?: AlertProps['variant']
  elevation?: AlertProps['elevation']
  onClose?: AlertProps['onClose']
  icon?: AlertProps['icon']
  rounded?: boolean
  className?: string
}

const useCustomSeverityStyles = makeStyles((theme) => ({
  info: {
    color: theme.color.white,
    backgroundColor: theme.color.darkerBlue,
  },
}))

const Alert: React.FC<Props> = ({ title, children, rounded = true, className, ...alertProps }) => {
  const classes = useCustomSeverityStyles()
  const severity = alertProps.severity || 'info'
  const customSeverityClass = classes[severity] || null
  const classNames = [
    ...(customSeverityClass ? [customSeverityClass] : []),
    ...(className ? [className] : []),
  ].join(' ')

  return (
    <S.Alert className={classNames} rounded={rounded ? 1 : 0} severity={severity} {...alertProps}>
      {title && (
        <S.TitleContainer>
          <Typography variant="BODY_LARGE" weight="bold">
            {title}
          </Typography>
        </S.TitleContainer>
      )}
      {children}
    </S.Alert>
  )
}

export default Alert
