import { memo } from 'react'
import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`
  html {
    -webkit-text-size-adjust: 100%
  }

  body {
    background-color: ${(props) => props.theme.color.cultured};
  }

  a {
    color: inherit;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }
`

export default memo(GlobalStyle)
