import { gql } from '@apollo/client'

gql`
  query AuthProviderPostLoginUser {
    viewer {
      id
      name
      username
    }
  }
`

gql`
  mutation AuthProviderGenerateAuthCode($input: AuthUserInput!) {
    authUser(input: $input)
  }
`

gql`
  mutation AuthProviderCreateUserFromSocial($input: AuthUserInput!) {
    authUserFromSocial(input: $input)
  }
`

gql`
  mutation InitAuth($input: InitAuthInput!) {
    initAuth(input: $input) {
      success
      error {
        type
        message
      }
    }
  }
`

gql`
  mutation VerifyOtp($input: VerifyAuthInput!) {
    verifyOtp(input: $input) {
      ... on VerifyAuthResponse {
        accessToken
        expiresIn
        refreshToken
      }
      ... on AuthErrorResponse {
        message
        type
      }
    }
  }
`

gql`
  mutation RefreshToken($refreshToken: String!) {
    refreshToken(refreshToken: $refreshToken) {
      ... on RefreshTokenResponse {
        accessToken
        expiresIn
      }
      ... on AuthErrorResponse {
        message
        type
      }
    }
  }
`

export const REFRESH_TOKEN = gql`
  mutation RefreshToken($refreshToken: String!) {
    refreshToken(refreshToken: $refreshToken) {
      ... on RefreshTokenResponse {
        accessToken
        expiresIn
      }
      ... on AuthErrorResponse {
        message
        type
      }
    }
  }
`
