import styled from 'styled-components'
import { TextField as MuiTextField } from '@material-ui/core'

export const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  padding: 50px 120px;
  align-items: center;
  text-align: center;
  min-height: 315px;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    padding: 40px 25px;
  }
`

export const CodeField = styled(MuiTextField)`
  width: 100%;
  margin: 15px;

  .MuiOutlinedInput-root {
    border-radius: ${({ theme }) => theme.border.radius.xxxxxl};
  }

  fieldset {
    border-color: ${({ theme }) => theme.color.lightGray};
  }

  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: ${({ theme }) => theme.color.lightGray};
    border-width: 1px;
  }

  input {
    text-align: center;
  }

  ${({ theme }) => theme.breakpoints.down('xs')} {
    width: 100%;
    input {
      font-size: ${({ theme }) => theme.font.size.compact};
    }
  }
`

export const ErrorMessage = styled.div`
  display: flex;
  justify-content: center;
  color: ${({ theme }) => theme.color.error};
  width: 100%;
`
