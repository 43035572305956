import Link from 'next/link'
import { MouseEventHandler } from 'react'
import { CurrentPathTypeEnum, EventTypeEnum, trackEvent } from '@modules/analytics'
import * as S from './withLink.styles'

export type ButtonPropsLike = {
  onClick?: MouseEventHandler<HTMLButtonElement> | MouseEventHandler<HTMLElement>
}

export type WithLinkProps<P extends ButtonPropsLike> = {
  href: string
  target?: string
} & Omit<P, 'onClick'> &
  (
    | // Only onClick or analyticsClickEvent are allowed - not both.
    {
        onClick?: never
        analyticsClickEvent: {
          name: string
          properties?: Record<string, unknown>
          currentPathType: CurrentPathTypeEnum
        }
      }
    | { onClick: () => void; analyticsClickEvent?: never }
    | { onClick?: never; analyticsClickEvent?: never }
  )

export function withLink<
  LinkProps extends WithLinkProps<ButtonProps>,
  ButtonProps extends ButtonPropsLike
>(Component: React.ComponentType<ButtonProps>): React.FC<LinkProps> {
  const withLink: React.FC<LinkProps> = ({
    href,
    target,
    analyticsClickEvent,
    ...rest
  }: LinkProps) => {
    return (
      <Link href={href} passHref>
        <S.Anchor target={target} rel="noopener noreferrer">
          <Component
            {...((rest as unknown) as ButtonProps)}
            {...(analyticsClickEvent && {
              onClick: () => {
                trackEvent({
                  description: analyticsClickEvent.name,
                  type: EventTypeEnum.Click,
                  properties: analyticsClickEvent.properties,
                  currentPathType: analyticsClickEvent.currentPathType,
                })
                // Return true to allow the link's inherent click action to proceed,
                // and navigate to the href url.
                return true
              },
            })}
          ></Component>
        </S.Anchor>
      </Link>
    )
  }
  return withLink
}
