import * as auth0 from 'auth0-js'
import * as Sentry from '@sentry/nextjs'
import { AuthProviderCreateUserFromSocialMutationFn } from '@generated/graphql'

import { auth0Config } from '../../../config/auth0'
import AuthStorageManager, { PUBLIC_STORAGE_KEY } from '../AuthStorageManager'
import { sign } from 'jsonwebtoken'
import { Auth0Info } from '../Auth0/Auth0Context'

const webAuthConfig: auth0.AuthOptions = {
  domain: auth0Config.domain,
  clientID: auth0Config.client_id,
  scope: auth0Config.scope,
  audience: auth0Config.audience,
  responseType: 'id_token token',
}

export const client = new auth0.WebAuth(webAuthConfig)

let tokenRefreshTimer: ReturnType<typeof setInterval> | null = null

/**
 * Authenticates a user's email and verification code
 * @param email - the user's email address
 * @param verificationCode - the verification / auth code sent via email or sms
 * @returns LoginWithEmailCodeResponse - an object containing whether login was
 *  successful and, if applicable, an error message.
 */
export const loginWithEmailAuthCode = async (
  email: string,
  verificationCode: string,
  redirectUrl?: string
): Promise<LoginWithEmailCodeResponse> => {
  return new Promise((resolve) => {
    client.passwordlessLogin(
      {
        connection: 'email',
        verificationCode,
        email,
        redirectUri:
          process.env.NEXT_PUBLIC_AUTH0_BASE_URL +
          `/auth0/embedded-auth-callback?returnTo=${redirectUrl || window.location.pathname}`,
      },
      (err) => {
        if (!err) {
          resolve({
            error: undefined,
            success: true,
          })
        } else {
          resolve({
            error: {
              message: err.description,
            },
            success: false,
          })
        }
      }
    )
  })
}

export const loginWithGoogle = (redirectUrl?: string): void => {
  client.authorize({
    connection: 'google-oauth2',
    redirectUri:
      process.env.NEXT_PUBLIC_AUTH0_BASE_URL +
      `/auth0/embedded-auth-callback?returnTo=${
        redirectUrl || window.location.pathname
      }&from=google`,
  })
}

export type LoginWithEmailCodeResponse = {
  error?: {
    message?: string
  }
  errorMessage?: string
  success: boolean
}

/**
 * Clears the user token and closes the auth session.
 * @param args - options for logging out.
 */
export const logout = (args?: auth0.LogoutOptions): void => {
  tokenRefreshTimer && clearInterval(tokenRefreshTimer)
  tokenRefreshTimer = null
  AuthStorageManager.clear()
  client.logout(args ? args : {})
}

/**
 * Attempts to refresh the auth token, and
 * @returns A promised boolean indicating whether the token was successfully refreshed or not.
 */
export const refreshToken = (): Promise<boolean> => {
  return new Promise((resolve) => {
    client.checkSession(
      {
        redirectUri: process.env.NEXT_PUBLIC_AUTH0_BASE_URL + '/auth0/embedded-auth-callback',
      },
      (err, res) => {
        if (err) {
          // If the error object has a code, authentication has legitimately
          // failed.
          //
          // If an error code doesn't exist, the error is most likely
          // due to a lost network connection. There will be 4 more attempts
          // to refresh the token
          // TODO - test this.
          if (err.code) {
            Sentry.captureMessage(
              `[AuthProvider]: User logged out due to refresh token error ${err.code}`
            )
            // If checking the session fails, the user's
            // auth token is expired and/or not renewable.
            logout({ returnTo: process.env.NEXT_PUBLIC_AUTH0_BASE_URL + '/' })
          }
          resolve(false)
        } else {
          // Update the stored token with the new token info.
          AuthStorageManager.set(res)
          startTokenRefreshTimer((AuthStorageManager.get() as Auth0Info)!.expiresIn)
          resolve(true)
        }
      }
    )
  })
}

export const getAccessToken = async (): Promise<string | null> => {
  let accessToken: string | null =
    (await getUserToken()) ||
    (AuthStorageManager.get(PUBLIC_STORAGE_KEY) as Auth0Info)?.accessToken ||
    null

  if (!accessToken) {
    accessToken = sign({ sub: 'public', service: 'web' }, 'public')
    AuthStorageManager.set({ accessToken }, PUBLIC_STORAGE_KEY)
  }

  return accessToken
}

export const getUserToken = async (): Promise<string | null> => {
  let userToken: string | null = (AuthStorageManager.get() as Auth0Info)?.accessToken || null

  if (userToken && isTokenExpired(userToken)) {
    await refreshToken()
    userToken = (AuthStorageManager.get() as Auth0Info)?.accessToken || null
  }

  return userToken
}

const isTokenExpired = (token: string): boolean => {
  try {
    const exp = JSON.parse(atob(token.split('.')[1]))?.exp
    return !Number.isNaN(Number(exp)) && Date.now() >= exp ? true : false
  } catch {
    return false
  }
}

/**
 * Begins the token refresh timer.
 * @param intervalInSeconds - the number of seconds at which the token should be refreshed.
 * @returns void
 */
const startTokenRefreshTimer = (intervalInSeconds: number): void => {
  // Take the expiration time and subtract 10 seconds.
  // Within this time frame ↑, attempt to refresh the token
  // up to 4 times. When a token is successfully refreshed, the
  // timer will restart - otherwise, it will continue and attempt
  // to refresh the token up to 3 additional times.
  // This way, we should be able to account for the network connection
  // being lost during any of these attempts.
  const bufferOf30Seconds = 1000 * 30
  const interval = (intervalInSeconds * 1000 - bufferOf30Seconds) / 4
  tokenRefreshTimer && clearInterval(tokenRefreshTimer)
  tokenRefreshTimer = setInterval(async () => {
    await refreshToken()
  }, interval)
}

/**
 * Called from the login redirect callback page.
 * After logging in, the auth token and other information
 * is passed to the redirect callback page as hash parameters.
 * This function is called at that point to:
 *  a) Parse and validate the auth data in the hash parameter
 *  b) Store it in local storage or a cookie
 *  c) Initialize the auth client and start the token refresh cycle
 * In case we receive the queryParam ?from=google we need to handle
 * Google Auth. For that we execute a mutation (create user and link auth0 user)
 * in case a user with the same email already exist on the DB, need to link the new auth0 user
 * to the existent and to refresh the token to handle the flow with the previous auth0 user.
 * @returns void
 */
export const setAuthInfo = (
  mutation: AuthProviderCreateUserFromSocialMutationFn
): Promise<void> => {
  return new Promise<void>((resolve, reject) => {
    const queryParams = new URLSearchParams(window.location.search)
    const urlParams = new URLSearchParams(window.location.hash)
    // TODO - for some reason, state is not always properly parsed
    // from the hash for an unknown reason. So, we'll imperatively
    client.parseHash({ state: urlParams.get('state')! }, async (err, res) => {
      try {
        if (res) {
          if (
            res.expiresIn &&
            res.accessToken &&
            res.idTokenPayload?.email &&
            res.idTokenPayload?.sub
          ) {
            if (queryParams.get('from') === 'google') {
              try {
                const { data } = await mutation({
                  variables: {
                    input: {
                      email: res.idTokenPayload.email,
                      auth0Id: res.idTokenPayload.sub,
                    },
                  },
                })
                if (
                  data?.authUserFromSocial &&
                  data.authUserFromSocial !== res.idTokenPayload.sub
                ) {
                  await refreshToken()
                  resolve()
                  return
                }
              } catch (error) {
                console.log(error)
                throw new Error(
                  `[AuthProviderGoogle]: There was an error creating the user ${res.idTokenPayload.email}-${res.idTokenPayload.sub}`
                )
              }
            }
            AuthStorageManager.set(res)
            startTokenRefreshTimer(res.expiresIn)
            resolve()
          } else {
            throw new Error(
              '[AuthProvider]: Auth token does not contain necessary data: ' + JSON.stringify(res)
            )
          }
        } else if (err) {
          throw new Error(
            '[AuthProvider]: ' +
              (err.description || err.error_description || err.errorDescription || err.code)
          )
        } else {
          Sentry.captureMessage('[AuthProvider]: No response received from parsing hash')
        }
      } catch (error) {
        reject(error)
      }
    })
  })
}
