import { usePublicFeaturedFlagsQuery } from '@generated/graphql'
import FeatureFlagContext from './FeatureFlagContext'

export type Props = {
  children: React.ReactNode
}

const FeatureFlagProvider: React.FC<Props> = ({ children }) => {
  const { data } = usePublicFeaturedFlagsQuery({
    fetchPolicy: 'cache-first',
  })
  return (
    <FeatureFlagContext.Provider value={{ featureFlags: data?.publicFeaturedFlags }}>
      {children}
    </FeatureFlagContext.Provider>
  )
}

export default FeatureFlagProvider
