import 'cross-fetch/polyfill'
import {
  ApolloClient,
  NormalizedCacheObject,
  InMemoryCache,
  from,
  createHttpLink,
} from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import * as Sentry from '@sentry/nextjs'
import { setUser as setSentryUser } from '@sentry/nextjs'

import { getAuthCypress } from '@components/utils/integration-test'
import { getAccessToken, getUserToken } from '@modules/AuthProvider/utils/cognito'
import { getAnonymousId } from '@modules/analytics'
import introspection from '../generated/possible-types'
import { decode } from 'jsonwebtoken'

const httpLink = createHttpLink({
  uri: process.env.NEXT_PUBLIC_GRAPHQL_API_URL,
})

// Log any GraphQL errors or network error that occurred
const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
  if (graphQLErrors) {
    for (const err of graphQLErrors) {
      switch (err.extensions.code) {
        // Apollo Server sets code to UNAUTHENTICATED
        // when an AuthenticationError is thrown in a resolver
        case 'UNAUTHENTICATED': {
          // Modify the operation context with a new token
          const oldHeaders = operation.getContext().headers
          getUserToken().then((accessToken) => {
            operation.setContext({
              headers: {
                ...oldHeaders,
                authorization: accessToken ? `Bearer ${accessToken}` : null,
              },
            })
            // Retry the request, returning the new observable
            return forward(operation)
          })
          break
        }
        default: {
          Sentry.captureMessage(
            `[GraphQL error]: Message: ${err.message}, Code: ${err.extensions.code}, Location: ${err.locations}, Path: ${err.path}`,
            Sentry.Severity.Fatal
          )
        }
      }
    }
  }
  const networkConnectionMessage =
    typeof window !== 'undefined' && typeof window?.navigator?.onLine !== 'undefined'
      ? window.navigator.onLine === false
        ? 'No internet connection'
        : 'API Unreachable'
      : 'Network Status Unknown'
  if (networkError)
    Sentry.captureMessage(
      `[Network error] / ${networkConnectionMessage}: ${networkError}`,
      Sentry.Severity.Log
    )
})

const authLink = setContext(async (_, { headers }) => {
  let accessToken: string | null = await getAccessToken()

  let userToken: string | null = null
  if (process.env.NEXT_PUBLIC_PROTEGE_ENV !== 'integration') {
    userToken = (await getUserToken()) || null
  } else {
    const auth0Cypress = getAuthCypress()
    accessToken = auth0Cypress?.token || null
    userToken = auth0Cypress?.sub || null
  }

  if (userToken) {
    const userId = decode(userToken)
    setSentryUser({ id: userId?.toString() })
  }

  const analyticsAnonymousId = getAnonymousId()

  return {
    headers: {
      ...headers,
      ...(analyticsAnonymousId && { 'x-analytics-anonymous-id': analyticsAnonymousId }),
      authorization: accessToken ? `Bearer ${accessToken}` : null,
    },
  }
})

export const CognitoApolloClientSingleton = (() => {
  let client: ApolloClient<NormalizedCacheObject>

  return {
    getInstance: () => {
      if (!client) {
        client = new ApolloClient({
          ssrMode: typeof window === 'undefined',
          link: from([errorLink, authLink, httpLink]),
          cache: new InMemoryCache({
            possibleTypes: introspection.possibleTypes,
            typePolicies: {
              Query: {
                fields: {
                  MediaConfig: {
                    merge(existing = {}, incoming) {
                      return { ...existing, ...incoming }
                    },
                  },
                },
              },
            },
          }),
        })
      }

      return client
    },
  }
})()

export default CognitoApolloClientSingleton.getInstance()
