const companyData = {
  '@context': 'http://www.schema.org',
  '@type': 'Organization',
  name: 'Protégé',
  url: 'https://www.protege.app',
  logo: 'https://www.protege.app/images/email-logo.png',
  description:
    "Protégé's mission is to democratize access to opportunity by allowing anyone to submit their talent to the best experts in the world for feedback and the chance to become their protégé. We believe that the best talent deserves be head and get discovered, no matter background, family connections, or net worth.",
  address: {
    '@type': 'PostalAddress',
    streetAddress: '212 N. Sangamon St., 3A',
    addressLocality: 'Chicago',
    addressRegion: 'IL',
    postalCode: '60607',
    addressCountry: 'United States',
  },
}

const OrganizationJsonLD: React.FC = () => {
  return (
    <script
      type="application/ld+json"
      dangerouslySetInnerHTML={{ __html: JSON.stringify(companyData) }}
    />
  )
}

export default OrganizationJsonLD
