import { useContext } from 'react'
import { publicRuntimeConfig } from '../../config/config'
import Auth0Context, { Auth0ContextInterface } from './Auth0/Auth0Context'
import CognitoContext, { CognitoContextInterface } from './Cognito/CognitoContext'

export const useAuth = (): Auth0ContextInterface | CognitoContextInterface => {
  const context = publicRuntimeConfig.NEXT_PUBLIC_COGNITO_AUTH_ENABLED
    ? CognitoContext
    : Auth0Context
  return useContext(context)
}
