import { useState, memo } from 'react'
import { Box } from '@material-ui/core'

import LoadingSpinner from '@components/LoadingSpinner/LoadingSpinner'
import Typography from '@components/Typography/Typography'
import Button from '@components/Button/Button'
import * as S from './SecurityCodeForm.styles'

export type Props = {
  onValidateCode: (code: string) => void
  error: string
  loading: boolean
}

const SecurityCodeForm: React.FC<Props> = ({ onValidateCode, error, loading }) => {
  const [code, setCode] = useState<string>('')

  return (
    <S.FormContainer>
      <Typography variant="H300">CHECK YOUR EMAIL</Typography>
      <S.CodeField
        variant="outlined"
        placeholder={'Security Code'}
        type="number"
        onChange={(e) => setCode(e.target.value)}
      />
      {!!error && <S.ErrorMessage>{error}</S.ErrorMessage>}
      <Box mt="15px">
        {!loading && (
          <Button
            type="submit"
            variant="primary"
            size="large"
            onClick={() => {
              onValidateCode(code)
            }}
            disabled={!code}
          >
            Login
          </Button>
        )}
        {loading && <LoadingSpinner size="SMALL" />}
      </Box>
    </S.FormContainer>
  )
}

export default memo(SecurityCodeForm)
