import Typography from '@components/Typography/Typography'

const Copyright: React.FC = () => {
  return (
    <>
      <Typography variant="BODY_MEDIUM" color="graniteGray">
        {'© '}
        {new Date().getFullYear()} Protégé
      </Typography>
    </>
  )
}

export default Copyright
