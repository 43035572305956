import { publicRuntimeConfig } from '../../config/config'
import Auth0Provider from './Auth0/Auth0Provider'
import CognitoProvider from './Cognito/CognitoProvider'

export type Props = {
  children: React.ReactNode
}

const AuthProviderWrapper: React.FC = ({ children }: Props) => {
  const cognitoClient = !!publicRuntimeConfig.NEXT_PUBLIC_COGNITO_AUTH_ENABLED
  switch (cognitoClient) {
    case true:
      return <CognitoProvider>{children}</CognitoProvider>
    case false:
      return <Auth0Provider>{children}</Auth0Provider>
    default:
      throw new Error(`AuthProvider: Unsupport AuthProvider Type`)
  }
}

export default AuthProviderWrapper
