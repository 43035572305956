import Typography from '@components/Typography/Typography'
import { memo } from 'react'
import * as S from './SimpleStepper.style'

export type Props = {
  currentStep: number
  steps?: number[]
  hideText?: boolean
}

const SimpleStepper: React.FC<Props> = ({ currentStep, steps = [1, 2, 3], hideText = false }) => {
  return (
    <S.Paper>
      {!hideText && (
        <div>
          <Typography
            variant="H100"
            uppercase
            color="primaryDarkContrast"
          >{`Step ${currentStep} of ${steps.length}`}</Typography>
        </div>
      )}
      {steps.map((step) => {
        if (step <= currentStep) return <S.CurrentStep key={step.toString()} />
        return <S.Step key={step.toString()} />
      })}
    </S.Paper>
  )
}

export default memo(SimpleStepper)
