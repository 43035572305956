import { Auth0DecodedHash } from 'auth0-js'
import store from 'store'
import { Auth0Info } from './Auth0/Auth0Context'

export const PUBLIC_STORAGE_KEY = 'protege-public-token'
export const USER_STORAGE_KEY = 'protege-user'

class AuthStorageManager {
  static set = (authInfo: Auth0DecodedHash | null | string, key = USER_STORAGE_KEY): void => {
    store.set(key, authInfo)
  }

  static get = (key = USER_STORAGE_KEY): Auth0Info | null | string => {
    return store.get(key)
  }

  static clear = (key = USER_STORAGE_KEY): void => {
    store.remove(key)
  }
}

export default AuthStorageManager
