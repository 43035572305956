import { CohortTypeEnum } from '@generated/graphql'

export const getPossessive = (input: string): string => {
  input = input.trim()
  input = input.toLowerCase().slice(-1) === 's' ? input + "'" : input + "'s"

  return input
}

export const validateEmail = (email: string): boolean => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(String(email).toLowerCase())
}

export const getExpertTitle = (cohortType: CohortTypeEnum, expertName?: string): string => {
  if (cohortType === CohortTypeEnum.Coaching) return expertName || ''
  return `${getPossessive(expertName || '')} Submissions`
}

export const formatPrice = (price: number): string =>
  new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
  }).format(price)
