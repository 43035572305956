import { useAuth } from '@modules/AuthProvider/useAuth'
import { Toolbar, Box } from '@material-ui/core'
import { useHeaderQuery } from '@generated/graphql'
import SearchIcon from '@material-ui/icons/Search'
import HomeIcon from '@material-ui/icons/Home'
import ProfileIcon from '@material-ui/icons/AccountCircle'
import AccountBalanceIcon from '@material-ui/icons/AccountBalanceWallet'

import * as S from './Header.styles'

const Header: React.FC = () => {
  const { isLoading } = useAuth()
  const { data } = useHeaderQuery({
    fetchPolicy: 'cache-and-network',
    skip: isLoading,
  })

  return (
    <S.Container>
      <S.AppBar color="transparent">
        <Toolbar>
          <Box display="flex" justifyContent="space-between" width={1} alignItems="center">
            <S.Link href={'/'} disableRipple>
              <S.Logo logoColor="darkGray">PROTÉGÉ</S.Logo>
            </S.Link>
          </Box>
        </Toolbar>
      </S.AppBar>
    </S.Container>
  )
}

export default Header
